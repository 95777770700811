import axios from 'axios'

const prefix = `api/suppliers`

const suppliers = {
    list: (params) => axios.get(`${prefix}/`, { params }),
    create: async (supplier) => axios.post(`${prefix}/`, supplier),
    read: async (supplier) => axios.get(`${prefix}/${supplier}`),
    query: async (data) => axios.post(`${prefix}/query`, data),
    update: async (supplier) => axios.patch(`${prefix}/${supplier.id}`, supplier),
    delete: async (supplier) => axios.delete(`${prefix}/${supplier}`),
}

export default suppliers
