import React from 'react'
import { Box, Grid, Paper } from '@mui/material'

const Container = ({ children, isFullWidth }) => {
    const size = isFullWidth ? { xs: 12 } : { xs: 12, sm: 10, md: 8, lg: 6 }

    const gutter = isFullWidth ? { xs: 0 } : { xs: 0, sm: 1, md: 2, lg: 3 }

    return (
        <Box pt={2} sx={{ width: '100%' }}>
            <Paper elevation={0} variant="outlined" sx={{ minHeight: 500 }}>
                <Grid container>
                    <Grid item {...gutter} />
                    <Grid item {...size}>
                        {children}
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    )
}

export default Container
