import * as Yup from 'yup'
export const formFields = [
    {
        component: 'MuiTextField',
        type: 'email',
        name: 'email',
        label: 'Email',
    },
    {
        component: 'MuiTextField',
        type: 'password',
        name: 'password',
        label: 'Password',
    },
]

export const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid Email').required('Email is Required'),
    password: Yup.string().min(12, 'Password must be more than 12 Characters long.'),
})
