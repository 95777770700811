import React from 'react'
import { Route, Routes } from 'react-router-dom'
import List from './list'
import Create from './create'
import Update from './update'
import Read from './read'

const Suppliers = () => (
    <Routes>
        <Route key={'list'} path={'/'} element={<List />} />
        <Route key={'create'} path={'/create'} element={<Create />} />
        <Route key={'read'} path={'/:id/read'} element={<Read />} />
        <Route key={'update'} path={'/:id/update'} element={<Update />} />
    </Routes>
)

export default Suppliers
