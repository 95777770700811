import React, { useCallback, useEffect, useState } from 'react'
import { DashboardPage } from 'lib/components/layout/page'
import api from 'app/api'
import { Grid } from '@mui/material'
import { categories, tableColumns } from '../constants'
import tableActionButtons from 'lib/components/bits/TableActionButtons'
import { useNavigate } from 'react-router-dom'
import { useDialog } from 'lib/context/Dialog'
import { toast } from 'react-toastify'

import { MuiAlert } from '@instantia/mui-kit.ui'
import TableContainer from 'lib/components/bits/TableContainer'
import Kpi from 'lib/components/data/Kpi'
import { Stack } from '@mui/system'
import Pie from 'lib/components/data/Pie'

const List = () => {
    const entity = 'invoices'
    const dialog = useDialog()
    const navigate = useNavigate()
    const [invoices, setInvoices] = useState([])

    const getInvoices = useCallback(() => {
        api({ entity, action: 'list' })
            .then(({ data }) => {
                setInvoices(data)
            })
            .catch((err) => console.error('failed to get invoices', err))
    }, [])

    const handleDeleteInvoice = (_invoice) => {
        api({ entity: 'invoices', action: 'delete', data: _invoice }).then(({ status }) => {
            if (status === 204) {
                const updated = invoices.filter((item) => _invoice !== item.id)
                setInvoices(updated)
                toast.success('Remove invoice successfully')
            }
        })
    }
    useEffect(() => {
        getInvoices()
    }, [getInvoices])

    const Actions = ({ row }) =>
        tableActionButtons({
            title: 'Invoices',
            actions: {
                view: () => navigate(`/invoices/${row.id}/read`),

                delete: () => {
                    dialog.setData({
                        title: 'Confirm Delete',
                        content:
                            'Deleting invoice can not be reversed. Are you sure you want to continue?',
                        handleConfirm: () => handleDeleteInvoice(row.id),
                    })
                },
            },
        })

    return (
        <DashboardPage title="Invoices">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <MuiAlert
                        severity="info"
                        title="info"
                        description="Invoices are generated from active jobs. Go to the jobs page to complete a job and generate a new invoice"
                        action={{
                            callback: () => navigate('/jobs'),
                            label: 'Jobs',
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Stack spacing={1}>
                        {categories.map((item) => (
                            <Kpi key={item.title} {...item} />
                        ))}

                        <Pie
                            dimension="$status"
                            measures={{ metric: { $sum: 1 } }}
                            entity="invoices"
                            title="Status"
                        />
                    </Stack>
                </Grid>

                <Grid item xs={12} sm={6} md={8} lg={9}>
                    <TableContainer
                        minHeight={720}
                        columns={tableColumns(Actions)}
                        rows={invoices}
                    />
                </Grid>
            </Grid>
        </DashboardPage>
    )
}

export default List
