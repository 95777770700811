export const tableColumns = (Actions) => {
    return [
        {
            field: 'customer',
            headerName: 'Customer',
            valueGetter: ({ row }) => row.customer.name || '',
            width: 180,
        },
        {
            field: 'vehicle',
            headerName: 'Vehicle',
            valueGetter: ({ row }) =>
                `${row.vehicle.registration} [${row.vehicle.make} ${row.vehicle.model}]`,
            width: 250,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 100,
        },
        {
            field: 'lines',
            headerName: '# Items',
            valueGetter: ({ row }) => row.lines.length,
            width: 75,
        },
        {
            field: 'total',
            headerName: 'Total',
            valueGetter: ({ row }) => `£ ${row.subTotal || 0} (Ex Vat)`,
            width: 75,
        },
        {
            field: 'id',
            headerName: '',
            renderCell: ({ row }) => <Actions row={row} />,
            width: 120,
        },
    ]
}

export const categories = [
    {
        qId: '64977314c304121d0a3a8ff0',
        entity: 'queryStore',
        title: 'Invoices Total',
        prefix: '£',
    },
    {
        qId: '64977352c304121d0a3a8ff1',
        entity: 'queryStore',
        title: 'Invoices Avg',
        prefix: '£',
    },
]
