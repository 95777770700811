import {
    Customers,
    Dashboard,
    Expenses,
    Invoices,
    Jobs,
    Landing,
    Quotes,
    Services,
    Settings,
    SignIn,
    SignUp,
    Suppliers,
    Tenants,
    Users,
} from 'app/pages'

export const publicRoutes = [
    { key: 'sign-in', path: '/sign-in', Page: SignIn },
    { key: 'sign-up', path: '/sign-up', Page: SignUp },
]

export const baseAppRoutes = [
    { key: 'home', path: '/', Page: Landing },
    { key: 'tenants', path: '/tenants/*', Page: Tenants, requireAuth: true },
]

export const tenantAppRoutes = [
    { key: 'dashboard-home', path: '/', Page: Dashboard, requireAuth: true },
    { key: 'customers', path: '/customers/*', Page: Customers, requireAuth: true },
    { key: 'services', path: '/services/*', Page: Services, requireAuth: true },
    { key: 'settings', path: '/settings/*', Page: Settings, requireAuth: true },
    { key: 'expenses', path: '/expenses/*', Page: Expenses, requireAuth: true },
    { key: 'suppliers', path: '/suppliers/*', Page: Suppliers, requireAuth: true },
    { key: 'jobs', path: '/jobs/*', Page: Jobs, requireAuth: true },
    { key: 'invoices', path: '/invoices/*', Page: Invoices, requireAuth: true },
    { key: 'quotes', path: '/quotes/*', Page: Quotes, requireAuth: true },
    { key: 'users', path: '/users/*', Page: Users, requireAuth: true },
]
