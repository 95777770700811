import React from 'react'
import { FormBuilder } from '@instantia/mui-kit.ui'
import { formFields, initialValues, validationSchema } from './constants'
import { Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Layout from 'lib/components/layout/authentication/Layout'

const SignUp = () => {
    const handleSubmit = () => {}
    const navigate = useNavigate()

    return (
        <Layout>
            <FormBuilder
                initialValues={initialValues}
                handleSubmit={handleSubmit}
                validationSchema={validationSchema}
                formFields={formFields}
                title="Sign Up"
                submitButtonText="Sign Up"
                submitFullWidth
            />

            <Typography color="primary" sx={{ pb: 1 }}>
                Already have an account? Sign in.
            </Typography>

            <Button
                color="primary"
                variant="outlined"
                fullWidth
                onClick={() => {
                    navigate('/sign-in')
                }}
            >
                Sign In
            </Button>
        </Layout>
    )
}

export default SignUp
