import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { generateBreadcrumbs } from 'lib/utilities'
import { DashboardPage } from 'lib/components/layout/page'
import { FormContainer } from 'lib/components/layout/form'
import { FormBuilder } from '@instantia/mui-kit.ui'
import { formFields, getCustomer, validationSchema } from '../constants'
import api from 'app/api'
import { toast } from 'react-toastify'

const Update = () => {
    const [customer, setCustomer] = useState()
    const { id } = useParams()
    const { pathname } = useLocation()

    const breadcrumbs = generateBreadcrumbs({
        icon: 'WorkOutline',
        name: 'Customer',
        pathname,
        action: 'update',
    })

    useEffect(() => {
        getCustomer(id, setCustomer)
    }, [id])

    const handleUpdateCustomer = async (data) => {
        const res = await api({ entity: 'customers', action: 'update', data }).catch(() => {
            toast.error('Error creating ')
        })

        if (res.status === 200) {
            setCustomer(res.data)
            toast.success('Customer updated successfully')
        }
    }

    if (!customer) return ''

    console.log(customer)

    return (
        <DashboardPage title="Update Customer" breadcrumbs={breadcrumbs}>
            <FormContainer>
                <FormBuilder
                    title="Update Customer"
                    initialValues={customer}
                    formFields={formFields}
                    validationSchema={validationSchema}
                    submitButtonText={'Update Customer'}
                    handleSubmit={handleUpdateCustomer}
                />
            </FormContainer>
        </DashboardPage>
    )
}

export default Update
