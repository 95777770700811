import React, { useEffect, useState } from 'react'
import { DashboardPage } from 'lib/components/layout/page'
import { useNavigate, useParams } from 'react-router-dom'
import RtlBox from 'lib/components/bits/RtlBox'
import { Box, Button, CardActions, Grid, Paper, Typography } from '@mui/material'
import { categories, getSupplier } from '../constants'
import NothingToShow from 'lib/components/bits/NothingToShow'
import { SimpleKpi } from '@instantia/mui-kit.ui'

const Read = () => {
    const [supplier, setSupplier] = useState(null)
    const { id } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        getSupplier(id).then((data) => setSupplier(data))
    }, [id])

    if (!supplier) return ''

    return (
        <DashboardPage title="Supplier">
            <RtlBox>
                <Button
                    onClick={() => navigate(`/suppliers/${id}/update`)}
                    sx={{ ml: 1 }}
                    variant="outlined"
                >
                    Update Supplier
                </Button>
            </RtlBox>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={6}>
                    <Paper variant="outlined" sx={{ p: 2 }}>
                        <Box sx={{ height: 59 }}>
                            <Typography variant="subtitle1">{supplier.name}</Typography>
                            <Typography>{supplier.description}</Typography>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Grid container spacing={1}>
                        {categories.map((item) => (
                            <Grid item xs={12} sm={6} key={item.title}>
                                <SimpleKpi metric={item.metric} title={item.title} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Paper variant="outlined" sx={{ p: 2 }}>
                        <Typography variant="subtitle1">{`Supplier expenses`}</Typography>
                        <Box height={250}>
                            <NothingToShow />
                        </Box>
                        <CardActions>
                            <Button variant="text" size="small">
                                View details
                            </Button>
                        </CardActions>
                    </Paper>
                </Grid>
            </Grid>
        </DashboardPage>
    )
}

export default Read
