import React from 'react'
import { Box, Container } from '@mui/material'
import { AppLogo } from '@instantia/mui-kit.ui'
import Logo from 'assets/images/logo/maneja.png'
import useStyles from 'theme/styles'
import Footer from 'lib/components/bits/Footer'

import Jumbotron from './partials/Jumbotron'
import Pricing from './partials/Pricing'
import RegisterInterest from './partials/RegisterInterest'
import ChessRow from './partials/ChessRow'
import MoreFeatures from './partials/MoreFeatures'

const Landing = () => {
    const { centerContent, rowLeftContent } = useStyles()

    return (
        <Box className={centerContent}>
            <Container>
                <Box className={rowLeftContent}>
                    <Box
                        sx={{
                            width: 150,
                            p: 3,
                        }}
                    >
                        <AppLogo img={{ src: Logo }} />
                    </Box>
                </Box>
            </Container>
            <Box
                sx={{
                    width: '100%',
                    height: 425,
                    backgroundImage: 'linear-gradient(to top, #ffffff, #6381a8)',
                }}
            >
                <Jumbotron />

                <ChessRow />

                <MoreFeatures />

                <Pricing />

                <RegisterInterest id={'register-interest'} />

                <Footer />
            </Box>
        </Box>
    )
}

export default Landing
