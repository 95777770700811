import * as Yup from 'yup'
import { makeDropDownOptions } from 'lib/utilities'

export const formFields = (vehicles) => {
    return [
        {
            component: 'MuiSelectField',
            name: 'vehicle',
            label: 'Vehicle',
            options: makeDropDownOptions(vehicles, 'registration', 'id'),
        },
        {
            component: 'MuiDateField',
            name: 'date',
            label: 'Quote Date',
        },
        {
            component: 'MuiTextField',
            name: 'notes',
            label: 'Quote/Repair/Service Notes',
            multiline: true,
            rows: 5,
        },
    ]
}

export const validationSchema = Yup.object().shape({
    vehicle: Yup.string().required(),
    date: Yup.string(),
    notes: Yup.string(),
})
