import React from 'react'
import { Grid } from '@mui/material'
import { settings } from './constants'
import TremIconCard from 'lib/components/bits/TremIconCard'

const List = () => {
    const renderGrid = (page, index) => {
        if (!page.description) return

        return (
            <Grid key={index} item xs={12} sm={6} md={4}>
                <TremIconCard {...page} />
            </Grid>
        )
    }

    return (
        <Grid container spacing={1}>
            {settings?.map((page, index) => renderGrid(page, index))}
        </Grid>
    )
}

export default List
