import React, { useEffect, useState } from 'react'
import { DashboardPage } from 'lib/components/layout/page'
import api from 'app/api'
import { Button } from '@mui/material'
import { MuiIcon } from '@instantia/mui-kit.ui'
import { useNavigate } from 'react-router-dom'
import { tableColumns } from '../constants'
import { useDialog } from 'lib/context/Dialog'
import { toast } from 'react-toastify'
import TableContainer from 'lib/components/bits/TableContainer'

const List = () => {
    const [loading, setLoading] = useState(true)
    const [services, setServices] = useState([])
    const dialog = useDialog()

    const navigate = useNavigate()
    const getServices = async () => {
        api({ entity: 'services', action: 'list' })
            .then(({ data, status }) => {
                if (status === 200) {
                    setServices(data)
                }
            })
            .catch((err) => console.error(err))
            .finally(() => setLoading(false))
    }

    const handleDeleteService = async (id) => {
        setLoading(true)
        api({ entity: 'services', action: 'delete', data: id })
            .then(({ status }) => {
                if (status === 204) {
                    const updatedServices = services.filter((service) => service.id !== id)
                    setServices(updatedServices)
                    toast.success('Service deleted successfully')
                }
            })
            .catch(() => toast.error('Failed to delete service'))
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getServices()
    }, [])

    const tableActionCallbacks = (id) => {
        return {
            view: () => navigate(`/services/${id}/read`),
            update: () => navigate(`/services/${id}/update`),
            delete: () => {
                dialog.setData({
                    title: 'Confirm Delete',
                    content:
                        'Deleting service can not be reversed. Are you sure you want to continue',
                    handleConfirm: () => handleDeleteService(id),
                })
                dialog.setOpen(true)
            },
        }
    }

    return (
        <DashboardPage title="Services">
            <TableContainer
                action={
                    <Button
                        variant="outlined"
                        onClick={() => navigate('/services/create')}
                        startIcon={<MuiIcon name="AddCircleOutline" />}
                    >
                        New Service
                    </Button>
                }
                minHeight={461}
                loading={loading}
                columns={tableColumns(tableActionCallbacks)}
                rows={services}
            />
        </DashboardPage>
    )
}

export default List
