import { Box, Button, Paper, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'
import { FeIcon } from '@instantia/mui-kit.ui'

const PricingItem = ({ image, title, price, interval, items }) => {
    const handlePricingClick = () => {
        const element = document.getElementById('register-interest')
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' })
        }
    }

    return (
        <Paper sx={{ borderRadius: 3 }}>
            <Box
                sx={{
                    display: 'flex',
                    // flexDirection: 'inline',
                    // alignItems: 'center',
                    width: '100%',
                    height: 150,
                    backgroundImage: `url(${image})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    borderRadius: 2,
                }}
            >
                <Box p={2}>
                    <Typography
                        variant="h3"
                        sx={{
                            color: '#ffffff',
                            fontSize: '2.1em',
                            fontWeight: 900,
                        }}
                    >
                        {title}
                    </Typography>
                </Box>
                <Box
                    p={2}
                    sx={{
                        width: '100%',
                        alignSelf: 'flex-end',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Typography variant="h4">
                        {price}
                        <sup>
                            <small> /{interval}</small>
                        </sup>
                    </Typography>
                </Box>
            </Box>

            <Box p={2} sx={{ width: '100%' }}>
                <Stack spacing={1}>
                    {items.map((item) => (
                        <Box key={item} sx={{ display: 'flex', flexDirection: 'row' }}>
                            <FeIcon name={'Minus'} />
                            <Typography sx={{ ml: 1 }}>{item}</Typography>
                        </Box>
                    ))}
                </Stack>
            </Box>

            <Box p={2}>
                <Button fullWidth color="primary" onClick={handlePricingClick}>
                    GET STARTED
                </Button>
            </Box>
        </Paper>
    )
}

export default PricingItem
