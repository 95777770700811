import React, { useEffect, useState } from 'react'
import api from 'app/api'
import PropTypes from 'prop-types'
import { SimpleKpi } from '@instantia/mui-kit.ui'

const Kpi = ({ dimension, measures, filters, entity, title, qId, prefix, suffix }) => {
    const [metric, setMetric] = useState('0')

    const loadKpiMetric = async () => {
        const data = qId || { dimension, measures, filters }

        const res = await api({ entity, action: 'query', data })
        if (res.data[0]) {
            setMetric(res.data[0].metric)
        }
    }

    useEffect(() => {
        loadKpiMetric()
        // eslint-disable-next-line
    }, [])

    return <SimpleKpi title={title} suffix={suffix} prefix={prefix} metric={metric} />
}

Kpi.propTypes = {
    qId: PropTypes.string,
    dimension: PropTypes.string,
    measures: PropTypes.shape({}),
    filter: PropTypes.shape({}),
    entity: PropTypes.string,
    title: PropTypes.string,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
}

Kpi.defaultProps = {
    entity: 'queryStore',
    qId: null,
    dimension: null,
    measures: {},
    filters: {},
    title: null,
    prefix: null,
    suffix: null,
}
export default Kpi
