import React from 'react'
import { Box, IconButton, Tooltip } from '@mui/material'
import { MuiIcon } from '@instantia/mui-kit.ui'
import PropTypes from 'prop-types'

const actionList = {
    view: { icon: 'ViewModule', label: 'View' },
    update: { icon: 'DriveFileRenameOutline', label: 'Update' },
    configure: { icon: 'Settings', label: 'Configure' },
    delete: { icon: 'DeleteOutline', label: 'Delete' },
}

const TableActionButtons = ({ title, actions }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
                justifyContent: 'space-between',
            }}
        >
            {Object.keys(actions).map((key) => {
                const { label, icon } = actionList[key]
                if (actions[key].Element) {
                    return actions[key].Element
                }

                return (
                    <Tooltip title={`${label} ${title}`} key={key}>
                        <IconButton size="small" onClick={() => actions[key]()}>
                            <MuiIcon name={icon} color="primary" />
                        </IconButton>
                    </Tooltip>
                )
            })}
        </Box>
    )
}

TableActionButtons.propTypes = {
    title: PropTypes.string,
    actions: PropTypes.shape({}),
}

TableActionButtons.defaultProps = {
    title: '',
    actions: {},
}
export default TableActionButtons
