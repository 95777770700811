import React from 'react'
import { DashboardPage } from 'lib/components/layout/page'
import { useLocation, useNavigate } from 'react-router-dom'
import { generateBreadcrumbs } from 'lib/utilities'
import { FormContainer } from 'lib/components/layout/form'
import { FormBuilder } from '@instantia/mui-kit.ui'
import { formFields, initialValues, validationSchema } from '../constants'
import api from 'app/api'
import { toast } from 'react-toastify'

const Create = () => {
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const breadcrumbs = generateBreadcrumbs({
        icon: 'WorkOutline',
        name: 'Customer',
        pathname,
        action: 'create',
    })

    const handleCreateCustomer = async (data) => {
        const res = await api({ entity: 'customers', action: 'create', data }).catch(() => {
            toast.error('Error creating ')
        })

        if (res.status === 200) {
            navigate(`/customers/${res.data.id}/read`)
        }
    }

    return (
        <DashboardPage title="Customers" breadcrumbs={breadcrumbs}>
            <FormContainer>
                <FormBuilder
                    title="New Customer"
                    initialValues={initialValues}
                    formFields={formFields}
                    validationSchema={validationSchema}
                    submitButtonText={'Create Customer'}
                    handleSubmit={handleCreateCustomer}
                />
            </FormContainer>
        </DashboardPage>
    )
}

export default Create
