export const tableColumns = [
    {
        field: 'service',
        headerName: 'Service',
        valueGetter: ({ row }) => row?.service.name,
    },
    {
        field: 'description',
        headerName: 'Notes',
        width: 350,
    },
    {
        field: 'charged',
        headerName: 'Charged',
        valueGetter: ({ row }) => `£ ${row?.charged.toFixed(2)}`,
    },
    {
        field: 'vat',
        headerName: 'Total',
        valueGetter: ({ row }) => `£ ${(row?.vat ? row?.charged * 1.2 : row?.charged).toFixed(2)}`,
    },
]
