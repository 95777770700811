import React, { useEffect, useState } from 'react'
import { DashboardPage } from 'lib/components/layout/page'
import { Box, Tab, Tabs } from '@mui/material'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import List from './List'
import { settings } from './constants'
import ExpenseCategories from './expenseCategories'

const Settings = () => {
    const [value, setValue] = useState('index')
    const navigate = useNavigate()
    const location = useLocation()
    const [, , page] = location.pathname.split('/')

    const handleChange = (event, _value) => {
        setValue(_value)
        navigate(`/settings/${_value}`)
    }

    useEffect(() => {
        if (page) {
            setValue(page)
        }
    }, [page])

    return (
        <DashboardPage title="Settings">
            <Box>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Settings" value="index" />
                    {settings.map(({ title, path }) => (
                        <Tab label={title} value={path} key={path} />
                    ))}
                </Tabs>

                <Box sx={{ pt: 2 }}>
                    <Routes>
                        <Route path={'/'} key="settings" element={<List />} />
                        <Route path={'/index'} key="settings" element={<List />} />
                        <Route
                            path={'/expense-categories/*'}
                            key="expense-categories"
                            element={<ExpenseCategories />}
                        />
                    </Routes>
                </Box>
            </Box>
        </DashboardPage>
    )
}

export default Settings
