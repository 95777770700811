import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
const PrivateRoute = ({ children }) => {
    const { isAuthenticated } = useSelector((state) => state.AUTH)

    if (isAuthenticated) return <>{children}</>

    return <Navigate to="/sign-in" replace={true} />
}

export default PrivateRoute
