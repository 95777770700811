import React from 'react'
import { Route, Routes } from 'react-router-dom'
import List from './list'
import Read from './read'

const Invoices = () => (
    <Routes>
        <Route key={'list'} path={'/'} element={<List />} />
        <Route key={'read'} path={'/:id/read'} element={<Read />} />
    </Routes>
)

export default Invoices
