import React from 'react'
import { Box, List, ListItem, Paper, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import useStyles from 'theme/styles'

const VehicleBox = ({ registration, make, model, fuel, transmission, engine }) => {
    const { rowSpaceBetween } = useStyles()
    return (
        <Paper variant="outlined" sx={{ p: 2 }}>
            <List>
                <ListItem>
                    <Typography variant="overline">{registration}</Typography>
                </ListItem>
                <ListItem>
                    <Box sx={{ pb: 1, width: '100%' }}>
                        <Box className={rowSpaceBetween}>
                            <Typography>Make</Typography>
                            <Typography>{make}</Typography>
                        </Box>

                        <Box className={rowSpaceBetween}>
                            <Typography>Model</Typography>
                            <Typography>{model}</Typography>
                        </Box>

                        <Box className={rowSpaceBetween}>
                            <Typography>Fuel Type</Typography>
                            <Typography>{fuel}</Typography>
                        </Box>
                        <Box className={rowSpaceBetween}>
                            <Typography>Transmission</Typography>
                            <Typography>{transmission}</Typography>
                        </Box>
                        <Box className={rowSpaceBetween}>
                            <Typography>Engine</Typography>
                            <Typography>{engine}</Typography>
                        </Box>
                    </Box>
                </ListItem>
            </List>
        </Paper>
    )
}

VehicleBox.propTypes = {
    registration: PropTypes.string.isRequired,
    make: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
    fuel: PropTypes.string,
    transmission: PropTypes.string,
    engine: PropTypes.string,
}

VehicleBox.defaultProps = {
    fuel: '',
    transmission: '',
    engine: '',
}
export default VehicleBox
