import palette from 'theme/palette'

const spacing = (input) => input * 8

const components = {
    MuiPaper: {
        defaultProps: {
            elevation: 0,
            rounded: 'true',
            // variant: 'outlined',
        },
        styleOverrides: {
            root: {
                borderRadius: spacing(1),
                padding: spacing(1),
            },
        },
    },
    MuiTypography: {
        styleOverrides: {},
    },
    MuiOutlinedInput: {
        styleOverrides: {
            root: {
                borderRadius: spacing(1),
            },
            notchedOutline: {
                borderWidth: 1,
            },
        },
    },
    MuiButton: {
        defaultProps: {
            variant: 'contained',
        },
        styleOverrides: {
            root: {
                padding: spacing(0.9),
                borderRadius: spacing(1),
                borderWidth: 1,
                '&:hover': {
                    fontWeight: 400,
                    borderRadius: spacing(0.5),
                },
            },
        },
    },
    MuiListItemButton: {
        styleOverrides: {
            root: {
                borderRadius: spacing(1),
                ':hover': {
                    backgroundColor: `${palette.grey[500_8]} !important`,
                },
            },
        },
    },
}

export default components
