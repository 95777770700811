import * as Yup from 'yup'

export const formFields = [
    {
        component: 'MuiTextField',
        name: 'subject',
        label: 'Subject',
    },
    {
        component: 'MuiTextField',
        name: 'comment',
        label: 'comment',
        multiline: true,
        rows: 5,
    },
]

export const validationSchema = Yup.object().shape({
    subject: Yup.string().required('Subject is required'),
    comment: Yup.string().required('Comment is required'),
})
