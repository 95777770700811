import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Update from './update'
import Create from './create'
import Read from './read'
import List from './list'

const Customers = () => (
    <Routes>
        <Route key={'list-customers'} path="/" element={<List />} />
        <Route key={'read-customer'} path="/:id/read" element={<Read />} />
        <Route key={'create-customers'} path="/create" element={<Create />} />
        <Route key={'update-customers'} path="/:id/update" element={<Update />} />
    </Routes>
)

export default Customers
