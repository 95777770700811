import { alpha } from '@mui/material/styles'

const GREY = {
    0: '#FFFFFF',
    100: '#F9FAFB',
    200: '#F4F6F8',
    300: '#DFE3E8',
    400: '#C4CDD5',
    500: '#919EAB',
    600: '#637381',
    700: '#454F5B',
    800: '#212B36',
    900: '#161C24',
    500_8: alpha('#919EAB', 0.08),
    500_12: alpha('#919EAB', 0.12),
    500_16: alpha('#919EAB', 0.16),
    500_24: alpha('#919EAB', 0.24),
    500_32: alpha('#919EAB', 0.32),
    500_48: alpha('#919EAB', 0.48),
    500_56: alpha('#919EAB', 0.56),
    500_80: alpha('#919EAB', 0.8),
}

const MuiPallete = {
    type: 'light',
    primary: {
        lighter: '#D1E9FC',
        light: '#76B0F1',
        dark: '#103996',
        darker: '#061B64',
        main: '#14213D',
        // main: '#2065D1',
        contrastText: '#ffffff',
    },
    secondary: {
        lighter: '#D6E4FF',
        light: '#84A9FF',
        main: '#3366FF',
        dark: '#1939B7',
        darker: '#091A7A',
        contrastText: '#fff',
    },
    error: {
        lighter: '#FFE7D9',
        light: '#FFA48D',
        main: '#FF4842',
        dark: '#B72136',
        darker: '#7A0C2E',
        contrastText: '#fff',
    },
    warning: {
        lighter: '#FFF7CD',
        light: '#FFE16A',
        main: '#FFC107',
        dark: '#B78103',
        darker: '#7A4F01',
        contrastText: GREY[800],
    },
    info: {
        lighter: '#DAE1EA',
        light: '#B9C1CD',
        main: '#778194',
        dark: '#35415A',
        darker: '#14213D',
        contrastText: '#fff',
    },
    success: {
        lighter: '#E9FCD4',
        light: '#AAF27F',
        main: '#54D62C',
        dark: '#229A16',
        darker: '#08660D',
        contrastText: GREY[800],
    },
    accent: {
        main: '#add8e6',
        contrastText: '#000000',
    },
    contrastThreshold: 3,
    tonalOffset: 0.1,
    background: {
        default: '#FAFAFF',
    },
    grey: GREY,
}

export default MuiPallete
