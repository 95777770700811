import { Stack } from '@mui/system'
import dayjs from 'dayjs'
import AddressBox from 'lib/components/bits/AddressBox'
import RtlBox from 'lib/components/bits/RtlBox'
import { upperCase } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Box, Button, Chip, Container, Grid, Paper, Typography } from '@mui/material'
import api from 'app/api'
import { DashboardPage } from 'lib/components/layout/page'
import { useParams } from 'react-router-dom'
import TableContainer from 'lib/components/bits/TableContainer'
import { tableColumns } from './constants'
import PricingFooter from 'lib/components/bits/PricingFooter'

const statusList = {
    paid: {
        color: 'success',
    },
    unpaid: {
        color: 'error',
    },
}

const Read = () => {
    const [invoice, setInvoice] = useState()
    const { id } = useParams()

    const getInvoice = () => {
        api({ entity: 'invoices', action: 'read', data: id })
            .then(({ data }) => setInvoice(data))
            .catch((err) => {
                console.error(err)
            })
    }

    useEffect(() => {
        getInvoice()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!invoice) return ''

    const { customer, date, status, notes, lines } = invoice

    return (
        <DashboardPage breadcrumbs={null} title="invoice">
            <Container>
                <Box sx={{ ml: -2, maxWidth: 300 }}>
                    <AddressBox name={customer.name} address={customer.address} outlined={false} />
                </Box>

                <Stack spacing={2}>
                    <Grid container>
                        <Grid item xs={10}>
                            <Box>
                                <Typography variant="subtitle1">Date</Typography>
                                <Typography>{dayjs(date).format('DD/MM/YYYY')}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <RtlBox>
                                <Chip
                                    // variant="outlined"
                                    label={upperCase(status)}
                                    color={statusList[status].color}
                                    size="small"
                                />
                            </RtlBox>
                        </Grid>
                    </Grid>

                    <Paper variant="outlined" sx={{ p: 2 }}>
                        <Typography variant={'subtitle1'}>Notes</Typography>
                        <Typography>{notes || 'Nothing to show'}</Typography>
                    </Paper>

                    <TableContainer
                        title="Invoice Lines"
                        disableSelectOnClick
                        hideFooter
                        minHeight={250}
                        columns={tableColumns}
                        rows={lines}
                    />

                    <RtlBox>
                        <PricingFooter {...invoice} />
                    </RtlBox>

                    <RtlBox>
                        {status === 'unpaid' && (
                            <Button onClick={() => console.info('Request Payment')} variant="link">
                                Request Payment
                            </Button>
                        )}

                        {status === 'Paid' && (
                            <Button onClick={() => console.info('send invoice')} variant="link">
                                Send Invoice
                            </Button>
                        )}
                    </RtlBox>
                </Stack>
            </Container>
        </DashboardPage>
    )
}
export default Read
