import React, { useEffect, useState } from 'react'
import { tableColumns } from '../constants'
import api from 'app/api'
import { Box, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useDialog } from 'lib/context/Dialog'
import { toast } from 'react-toastify'
import TableContainer from 'lib/components/bits/TableContainer'

const List = () => {
    const navigate = useNavigate()
    const dialog = useDialog()
    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(true)

    const getExpenseCategories = () => {
        api({ entity: 'expenseCategories', action: 'list' })
            .then(({ data }) => {
                setCategories(data)
            })
            .catch((err) => {
                console.error(err)
            })
            .finally(() => setLoading(false))
    }

    const handleDeleteExpenseCategory = (id) => {
        setLoading(true)
        api({
            entity: 'expenseCategories',
            action: 'delete',
            data: id,
        })
            .then(({ status }) => {
                if (status === 204) {
                    const expenseCategories = categories.filter((item) => item.id !== id)
                    setCategories(expenseCategories)
                    toast.success('Expense category deleted')
                }
            })
            .catch((err) => {
                console.error(err)
                toast.success('Error deleting expense category.')
            })
            .finally(() => setLoading(false))
    }

    const tableActionCallbacks = (id) => {
        return {
            update: () => navigate(`/settings/expense-categories/${id}/update`),
            delete: () => {
                dialog.setData({
                    title: 'Confirm Delete',
                    content:
                        'Deleting expense category can not be reversed. Are you sure you want to continue',
                    handleConfirm: () => handleDeleteExpenseCategory(id),
                })
                dialog.setOpen(true)
            },
        }
    }

    useEffect(() => {
        getExpenseCategories()
    }, [])

    return (
        <Box sx={{ pt: 1 }}>
            <TableContainer
                minHeight={461}
                action={
                    <Button variant="outlined" onClick={() => navigate('create')}>
                        New Expense Category
                    </Button>
                }
                columns={tableColumns(tableActionCallbacks)}
                rows={categories}
                loading={loading}
            />
        </Box>
    )
}

export default List
