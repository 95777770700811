import React, { useState } from 'react'
import { FormBuilder, MuiDrawer } from '@instantia/mui-kit.ui'
import { formFields, validationSchema } from '../constants'

const Update = ({ comment, handleUpdateComment }) => {
    const [formOpen, setFormOpen] = useState(false)

    const updateComment = async (comment) => {
        const updated = await handleUpdateComment(comment)
        if (updated) {
            setFormOpen(false)
        }
    }

    return (
        <MuiDrawer
            radius={2}
            open={formOpen}
            title="Update Comment"
            handleOpen={() => setFormOpen(true)}
            trigger={{ element: 'IconButton', icon: 'MoreHoriz' }}
        >
            <FormBuilder
                initialValues={comment}
                formFields={formFields}
                validationSchema={validationSchema}
                title={'Update Form'}
                handleSubmit={updateComment}
                submitButtonText="Update Comment"
            />
        </MuiDrawer>
    )
}

export default Update
