import React from 'react'
import { FormBuilder, MuiDrawer } from '@instantia/mui-kit.ui'
import { formFields, validationSchema } from '../constants'

const Create = ({ formOpen, toggleOpen, comment, handleCreate }) => {
    return (
        <MuiDrawer
            radius={2}
            open={formOpen}
            title="Create Comment"
            handleOpen={toggleOpen}
            handleClose={toggleOpen}
            trigger={null}
        >
            <FormBuilder
                initialValues={{
                    comment: '',
                    subject: '',
                }}
                formFields={formFields}
                validationSchema={validationSchema}
                title={'Create Form'}
                handleSubmit={handleCreate}
                submitButtonText="Create Comment"
            />
        </MuiDrawer>
    )
}

export default Create
