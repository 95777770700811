import React from 'react'
import { FormContainer } from 'lib/components/layout/form'
import { FormBuilder } from '@instantia/mui-kit.ui'
import { DashboardPage } from 'lib/components/layout/page'
import { formFields, validationSchema } from '../constants'
import api from 'app/api'
import { toast } from 'react-toastify'
import { generateBreadcrumbs } from 'lib/utilities'
import { useLocation, useNavigate } from 'react-router-dom'

const Create = () => {
    const service = {
        name: '',
        description: '',
        unitPrice: 0,
        unit: 'item',
    }
    const navigate = useNavigate()

    const { pathname } = useLocation()
    const breadcrumbs = generateBreadcrumbs({
        icon: 'HomeRepairService',
        name: 'Services',
        pathname,
        action: 'create',
    })

    const handleCreateService = async (data) => {
        const res = await api({ entity: 'services', action: 'create', data }).catch((err) => {
            console.error(err)
            toast.error('Error creating service')
        })

        if (res.status === 200) {
            toast.success('Service created successfully')
            navigate(-1)
        }
    }

    return (
        <DashboardPage title="Service" breadcrumbs={breadcrumbs}>
            <FormContainer>
                <FormBuilder
                    title="New Service"
                    initialValues={service}
                    validationSchema={validationSchema}
                    submitButtonText="Create Service"
                    formFields={formFields}
                    handleSubmit={handleCreateService}
                />
            </FormContainer>
        </DashboardPage>
    )
}

export default Create
