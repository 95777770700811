import axios from 'axios'

const prefix = `api/invoices`

const invoices = {
    list: (params) => axios.get(`${prefix}/`, { params }),
    create: async (invoice) => axios.post(`${prefix}/`, invoice),
    read: async (invoice) => axios.get(`${prefix}/${invoice}`),
    query: async (data) => axios.post(`${prefix}/query`, data),
    update: async (invoice) => axios.patch(`${prefix}/${invoice.id}`, invoice),
    delete: async (invoice) => axios.delete(`${prefix}/${invoice}`),
}

export default invoices
