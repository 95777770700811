import React from 'react'
import { Route, Routes } from 'react-router-dom'
import List from './list/List'
import Create from './create/Create'
import Update from './update'

const Expenses = () => {
    return (
        <Routes>
            <Route key="expenses" path={'/'} element={<List />} />
            <Route key="create" path={'/create'} element={<Create />} />
            <Route key="create" path={'/:id/update'} element={<Update />} />
        </Routes>
    )
}

export default Expenses
