import React, { useCallback, useEffect, useState } from 'react'
import { FormContainer } from 'lib/components/layout/form'
import RtlBox from 'lib/components/bits/RtlBox'
import { Button } from '@mui/material'
import { FormBuilder, MuiIcon } from '@instantia/mui-kit.ui'
import { formFields, validationSchema } from '../constants'
import { useNavigate, useParams } from 'react-router-dom'
import api from 'app/api'
import { toast } from 'react-toastify'

const Update = () => {
    const [loading, setLoading] = useState(true)
    const [category, setCategory] = useState()
    const { id } = useParams()
    const navigate = useNavigate()

    const getExpenseCategory = useCallback(() => {
        api({ entity: 'expenseCategories', action: 'read', data: id })
            .then(({ data }) => setCategory(data))
            .catch((err) => console.error(err))
            .finally(() => setLoading(false))
    }, [id])

    const handleUpdateExpenseCategory = (data) => {
        setLoading(true)
        api({ entity: 'expenseCategories', action: 'update', data })
            .then(({ status }) => {
                if (status === 200) {
                    toast.success('Expense Category updated successfully')
                    setCategory(data)
                }
            })
            .catch((err) => {
                console.error(err)
                toast.success('Error updating expense category')
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        getExpenseCategory()
    }, [getExpenseCategory])

    if (loading) return ''

    return (
        <FormContainer>
            <RtlBox>
                <Button
                    variant="outlined"
                    onClick={() => navigate(-1)}
                    startIcon={<MuiIcon name={'ArrowBack'} />}
                >
                    Back
                </Button>
            </RtlBox>
            <FormBuilder
                title={'New Expense Category'}
                formFields={formFields}
                initialValues={category}
                validationSchema={validationSchema}
                submitButtonText={'Update Expense Category'}
                handleSubmit={handleUpdateExpenseCategory}
            />
        </FormContainer>
    )
}

export default Update
