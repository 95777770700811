import React from 'react'
import { Route, Routes } from 'react-router-dom'
import List from './list'
import Read from './read'

const Quotes = () => (
    <Routes>
        <Route key={'list-quotes'} path={'/'} element={<List />} />
        <Route key={'read-quotes'} path={'/:id/read'} element={<Read />} />
    </Routes>
)

export default Quotes
