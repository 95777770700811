import { createSlice } from '@reduxjs/toolkit'

export const customerSlice = createSlice({
    name: 'CUSTOMER',
    initialState: {
        details: null,
        vehicles: [],
        quotes: [],
        jobs: [],
        invoices: [],
    },
    reducers: {
        setCustomerEntity: (state, { payload }) => {
            const { entity, data } = payload
            state[entity] = data
        },
    },
})

export const { setCustomerEntity } = customerSlice.actions

export default customerSlice.reducer
