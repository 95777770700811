import React from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material'
import PropTypes from 'prop-types'

const MuiDialog = ({ open, data, handleClose }) => {
    const { title, content, handleConfirm } = data
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
                </DialogContent>

                <DialogActions
                    sx={{
                        ph: 3,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Button variant={'outlined'} onClick={handleClose} size={'small'}>
                        Cancel
                    </Button>
                    <Button
                        size="small"
                        onClick={() => {
                            handleConfirm()
                            handleClose()
                        }}
                        autoFocus
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

MuiDialog.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
        handleConfirm: PropTypes.func,
    }),
}

MuiDialog.defaultProps = {
    data: {
        title: 'Alert',
        content: '',
        handleConfirm: () => console.info('Agree'),
    },
}

export default MuiDialog
