import * as Yup from 'yup'
import { makeDropDownOptions } from 'lib/utilities'

export const tableColumns = [
    {
        field: 'service',
        headerName: 'Service',
        valueGetter: ({ row }) => row?.service.name,
    },
    {
        field: 'description',
        headerName: 'Notes',
        width: 350,
    },
    {
        field: 'charged',
        headerName: 'Charged',
        valueGetter: ({ row }) => `£ ${row?.charged.toFixed(2)}`,
    },
    {
        field: 'vat',
        headerName: 'Total',
        valueGetter: ({ row }) => `£ ${(row?.vat ? row?.charged * 1.2 : row?.charged).toFixed(2)}`,
    },
]

export const validationSchema = Yup.object().shape({
    service: Yup.string().required('Select Invoice Line Service'),
    description: Yup.string().nullable(),
    charged: Yup.number(),
    addVat: Yup.boolean(),
})

export const formFields = (services) => {
    return [
        {
            component: 'MuiSelectField',
            name: 'service',
            label: 'Service',
            options: makeDropDownOptions(services, 'name', 'id'),
        },
        {
            component: 'MuiTextField',
            name: 'description',
            label: 'Description/Notes',
            multiline: true,
            rows: 3,
        },
        {
            component: 'MuiTextField',
            name: 'charged',
            label: 'Amount',
        },
        {
            component: 'MuiCheckboxField',
            name: 'vat',
            label: 'Add VAT (%20)',
        },
    ]
}
