import React, { useCallback, useEffect, useState } from 'react'
import { DashboardPage } from 'lib/components/layout/page'
import api from 'app/api'
import { Grid } from '@mui/material'
import { categories, tableColumns } from '../constants'
import tableActionButtons from 'lib/components/bits/TableActionButtons'
import { useNavigate } from 'react-router-dom'
import { useDialog } from 'lib/context/Dialog'
import { toast } from 'react-toastify'

import { MuiAlert } from '@instantia/mui-kit.ui'
import TableContainer from 'lib/components/bits/TableContainer'
import Kpi from 'lib/components/data/Kpi'
import { Stack } from '@mui/system'
import Pie from 'lib/components/data/Pie'

const List = () => {
    const entity = 'quotes'
    const dialog = useDialog()
    const navigate = useNavigate()
    const [quotes, setQuotes] = useState([])

    const getQuotes = useCallback(() => {
        api({ entity, action: 'list' })
            .then(({ data }) => {
                setQuotes(data)
            })
            .catch((err) => console.error('failed to get quotes', err))
    }, [])

    const handleDeleteQuote = (_quote) => {
        api({ entity: 'quotes', action: 'delete', data: _quote }).then(({ status }) => {
            if (status === 204) {
                const updated = quotes.filter((item) => _quote !== item.id)
                setQuotes(updated)
                toast.success('Remove quote successfully')
            }
        })
    }
    useEffect(() => {
        getQuotes()
    }, [getQuotes])

    const Actions = ({ row }) =>
        tableActionButtons({
            title: 'Quotes',
            actions: {
                view: () => navigate(`/quotes/${row.id}/read`),

                delete: () => {
                    dialog.setData({
                        title: 'Confirm Delete',
                        content:
                            'Deleting quote can not be reversed. Are you sure you want to continue?',
                        handleConfirm: () => handleDeleteQuote(row.id),
                    })
                },
            },
        })

    return (
        <DashboardPage title="Quotes">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <MuiAlert
                        severity="info"
                        title="info"
                        description="Quotes are generated from customers. Go to the customers page to generate a new quote"
                        action={{
                            callback: () => navigate('/customers'),
                            label: 'Customers',
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Stack spacing={1}>
                        {categories.map((item) => (
                            <Kpi key={item.title} {...item} />
                        ))}

                        <Pie
                            dimension="$status"
                            measures={{ metric: { $sum: 1 } }}
                            entity="quotes"
                            title="Status"
                        />
                    </Stack>
                </Grid>

                <Grid item xs={12} sm={6} md={8} lg={9}>
                    <TableContainer minHeight={720} columns={tableColumns(Actions)} rows={quotes} />
                </Grid>
            </Grid>
        </DashboardPage>
    )
}

export default List
