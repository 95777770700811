const MuiOverrides = {
    MuiCssBaseline: {
        '@global': {
            body: {
                background: '#F8F8FF',
            },
            html: {
                webkitFontSmoothing: 'auto',
            },
            '*': {
                'scrollbar-width': 'thin',
            },
            '*::-webkit-scrollbar': {
                width: '4px',
                height: '4px',
            },
        },
    },
}

export default MuiOverrides
