import React from 'react'
import { List, ListItem, Paper, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { Stack } from '@mui/system'

const AddressBox = ({ name, address, outlined }) => {
    const variant = outlined ? 'outlined' : ''
    const padding = outlined ? 2 : 0

    return (
        <Paper variant={variant} sx={{ p: padding }} elevation={0}>
            <List>
                <ListItem>
                    <Typography variant="overline">{name}</Typography>
                </ListItem>
                <ListItem>
                    <Stack>
                        <Typography>{address.line1}</Typography>
                        {address?.line2 && <Typography>{address.line2}</Typography>}
                        <Typography>{address.city}</Typography>
                        <Typography>{address.postalCode}</Typography>
                        <Typography>United Kingdom</Typography>
                    </Stack>
                </ListItem>
            </List>
        </Paper>
    )
}

AddressBox.propTypes = {
    outlined: PropTypes.bool,
    name: PropTypes.string.isRequired,
    address: PropTypes.shape({
        line1: PropTypes.string,
        line2: PropTypes.string,
        city: PropTypes.string,
        postalCode: PropTypes.string,
    }),
}

AddressBox.defaultProps = {
    outlined: true,
    address: {
        line1: 'Line 1: _',
        line2: null,
        city: 'City: _ ',
        postalCode: 'POST CODE: _',
    },
}

export default AddressBox
