import TableActionButtons from 'lib/components/bits/TableActionButtons'
import * as Yup from 'yup'
import dayjs from 'dayjs'

export const tableColumns = (actions) => [
    {
        field: 'name',
        headerName: 'Name',
        sortable: true,
        width: 180,
    },
    {
        field: 'description',
        headerName: 'Description',
        sortable: true,
        width: 300,
    },
    {
        field: 'created_at',
        headerName: 'Created',
        valueGetter: ({ row }) => dayjs(row.created_at).format('DD MMM YYYY'),
        sortable: true,
        width: 150,
    },
    {
        field: 'id',
        headerName: '',
        width: 100,
        renderCell: ({ row }) => (
            <TableActionButtons title={'customer'} actions={actions(row.id)} />
        ),
    },
]

export const formFields = [
    {
        component: 'MuiTextField',
        name: 'name',
        label: 'Name',
    },
    {
        component: 'MuiTextField',
        name: 'description',
        label: 'Description',
        multiline: true,
        rows: 5,
    },
]

export const validationSchema = Yup.object().shape({
    name: Yup.string().required('Purchase Category Name is required'),
    description: Yup.string().required('Purchase Category Description is required'),
})
