import React, { useEffect, useState } from 'react'
import { DashboardPage } from 'lib/components/layout/page'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { generateBreadcrumbs } from 'lib/utilities'
import { formFields, getSupplier, validationSchema } from '../constants'
import { FormContainer } from 'lib/components/layout/form'
import { FormBuilder } from '@instantia/mui-kit.ui'
import api from 'app/api'
import { toast } from 'react-toastify'

const Update = () => {
    const [supplier, setSupplier] = useState(null)
    const navigate = useNavigate()
    const { id } = useParams()
    const { pathname } = useLocation()
    const breadcrumbs = generateBreadcrumbs({
        icon: 'BusinessCenter',
        name: 'Suppliers',
        pathname,
        action: 'update',
    })

    const handleUpdateSupplier = async (data) => {
        const res = await api({ entity: 'suppliers', action: 'update', data }).catch((err) => {
            toast.error('Error updating supplier.')
            console.error(err)
        })
        if (res.status === 200) {
            navigate(-1)
            setSupplier(res.data)
            toast.success('Supplier updated successfully')
        }
    }

    useEffect(() => {
        getSupplier(id).then((data) => setSupplier(data))
    }, [id])

    if (!supplier) return ''

    return (
        <DashboardPage title="Update Supplier" breadcrumbs={breadcrumbs}>
            <FormContainer>
                <FormBuilder
                    title="Update supplier"
                    initialValues={supplier}
                    validationSchema={validationSchema}
                    submitButtonText="Update Supplier"
                    formFields={formFields}
                    handleSubmit={handleUpdateSupplier}
                />
            </FormContainer>
        </DashboardPage>
    )
}

export default Update
