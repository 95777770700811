import axios from 'axios'

const prefix = `api`

const auth = {
    signIn: async (user) => axios.post(`${prefix}/auth/sign-in`, user),
    signUp: async (user) => axios.post(`${prefix}/auth/sign-up`, user),
    verifyUser: async (user) => axios.put(`${prefix}/auth/verify-email`, user),
    verifyEmail: async () => axios.get(`${prefix}/auth/verify-email`),
    requestPasswordReset: async (user) => axios.post(`${prefix}/auth/request-password-reset`, user),
    resetPassword: async (user) => axios.post(`${prefix}/auth/reset-password/${user.token}`, user),
}

export default auth
