import React, { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CreateVehicle from './create'
import api from 'app/api'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { customerSelector } from 'app/redux/selectors'
import { setCustomerEntity } from 'app/redux/customer/slice'
import { useDialog } from 'lib/context/Dialog'
import tableActionButtons from 'lib/components/bits/TableActionButtons'
import TableContainer from 'lib/components/bits/TableContainer'
import { tableColumns } from './constants'

const Vehicles = () => {
    const dialog = useDialog()

    const { id } = useParams()
    const entity = 'vehicles'
    const dispatch = useDispatch()
    const { vehicles } = useSelector(customerSelector)

    const getVehicles = useCallback(() => {
        api({ entity, action: 'list', data: { customer: id } })
            .then(({ data }) => {
                dispatch(setCustomerEntity({ entity, data }))
            })
            .catch(() => {
                toast.error('Unknown error retrieving vehicles')
            })
    }, [id, dispatch])

    const handleCreateVehicle = (vehicle) => {
        api({
            entity: 'vehicles',
            action: 'create',
            data: vehicle,
        })
            .then(({ data, status }) => {
                if (status === 200) {
                    dispatch(setCustomerEntity({ entity, data: vehicles.concat(data) }))
                }
            })
            .catch((err) => {
                console.error(err)
                toast.error('Unknown error adding vehicle to customer')
            })
    }

    const handleUpdateVehicle = (_vehicle) => {
        api({
            entity: 'vehicles',
            action: 'update',
            data: _vehicle,
        })
            .then(({ data, status }) => {
                if (status === 200) {
                    const updated = vehicles.map((item) => (data.id === item.id ? data : item))
                    dispatch(setCustomerEntity({ entity, data: updated }))
                }
            })
            .catch(() => {
                toast.error('Unknown error updating vehicle')
            })
    }

    const handleDeleteVehicle = (_vehicle) => {
        api({ entity: 'vehicles', action: 'delete', data: _vehicle }).then(({ status }) => {
            if (status === 204) {
                const updated = vehicles.filter((item) => _vehicle !== item.id)
                dispatch(setCustomerEntity({ entity, data: updated }))
                toast.success('Remove vehicle successfully')
            }
        })
    }

    const Actions = ({ row }) =>
        tableActionButtons({
            title: 'vehicles',
            actions: {
                delete: () => {
                    dialog.setData({
                        title: 'Confirm Delete',
                        content:
                            'Deleting vehicle from customer can not be reversed. Are you sure you want to continue?',
                        handleConfirm: () => handleDeleteVehicle(row.id),
                    })
                    dialog.setOpen(true)
                },
            },
        })

    useEffect(() => {
        getVehicles()
        return () => dispatch(setCustomerEntity({ entity, data: null }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <TableContainer
            title="Vehicles"
            action={<CreateVehicle handleCreateVehicle={handleCreateVehicle} />}
            columns={tableColumns(Actions, handleUpdateVehicle)}
            rows={vehicles}
            minHeight={320}
        />
    )
}

export default Vehicles
