import React from 'react'
import { MuiAvatarIcon } from '@instantia/mui-kit.ui'
import { useNavigate } from 'react-router-dom'
import { Button, CardActions, Paper, Typography } from '@mui/material'

const TremIconCard = ({ title, path, icon, description }) => {
    const navigate = useNavigate()
    return (
        <Paper key={title}>
            {icon && <MuiAvatarIcon icon={icon} />}
            <Typography variant="subtitle1" sx={{ mt: 2 }}>
                {title}
            </Typography>
            <Typography sx={{ mt: 1 }}>{description}</Typography>
            {path && (
                <CardActions>
                    <Button
                        variant={'text'}
                        onClick={() => navigate(`/settings/${path}`)}
                        size="small"
                    >
                        {`View ${title}`}
                    </Button>
                </CardActions>
            )}
        </Paper>
    )
}

export default TremIconCard
