import axios from 'axios'

const prefix = `api/quotes`

const quotes = {
    list: (params) => axios.get(`${prefix}`, { params }),
    create: async (quote) => axios.post(`${prefix}`, quote),
    read: async (quote) => axios.get(`${prefix}/${quote}`),
    query: async (data) => axios.post(`${prefix}/query`, data),
    update: async (quote) => axios.patch(`${prefix}/${quote.id}`, quote),
    delete: async (quote) => axios.delete(`${prefix}/${quote}`),
    addLine: async (line) => axios.post(`${prefix}/${line.quote}/lines`, line),
    deleteLine: async (line) => axios.delete(`${prefix}/${line.quote}/lines/${line?._id}`),
    updateLine: async (line) => axios.patch(`${prefix}/${line.quote}/lines/${line?._id}`, line),
}

export default quotes
