import React, { useEffect, useState } from 'react'
import { DashboardPage } from 'lib/components/layout/page'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Grid } from '@mui/material'
import { categories, tableColumns } from './constants'
import api from 'app/api'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { MuiIcon } from '@instantia/mui-kit.ui'
import { toast } from 'react-toastify'
import { useDialog } from 'lib/context/Dialog'
import TableContainer from 'lib/components/bits/TableContainer'
import Kpi from 'lib/components/data/Kpi'
import Pie from 'lib/components/data/Pie'
import { Stack } from '@mui/system'

dayjs.extend(relativeTime)

const List = () => {
    const navigate = useNavigate()
    const [customers, setCustomers] = useState([])
    const [loading, setLoading] = useState(true)
    const dialog = useDialog()
    const getCustomers = () => {
        api({ entity: 'customers', action: 'list' })
            .then(({ data }) => {
                setCustomers(data)
            })
            .catch((err) => console.error(err))
            .finally(() => setLoading(false))
    }

    const handleDeleteCustomer = (id) => {
        setLoading(loading)
        api({ entity: 'customers', action: 'delete', data: id })
            .then(({ status }) => {
                if (status === 204) {
                    toast.success('Customer deleted successfully')
                    const _customers = customers.filter((item) => id !== item.id)
                    setCustomers(_customers)
                }
            })
            .catch((err) => {
                console.error(err)
                toast.error('Error deleting customer')
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        getCustomers()
    }, [])

    const tableActionCallbacks = (id) => {
        return {
            view: () => navigate(`/customers/${id}/read`),
            update: () => navigate(`/customers/${id}/update`),
            delete: () => {
                dialog.setData({
                    title: 'Confirm Delete',
                    content:
                        'Deleting customer can not be reversed. Are you sure you want to continue?',
                    handleConfirm: () => handleDeleteCustomer(id),
                })
                dialog.setOpen(true)
            },
        }
    }

    return (
        <DashboardPage title="Customers">
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box sx={{ pt: { xs: 0, sm: 1, md: 6.8 } }}>
                        <Stack spacing={1}>
                            {categories.map((item) => (
                                <Kpi
                                    key={item.title}
                                    measures={item.measures}
                                    entity={item.entity}
                                    title={item.title}
                                    filters={item.filters}
                                />
                            ))}

                            <Pie
                                dimension="$account"
                                measures={{ metric: { $sum: 1 } }}
                                entity="customers"
                                title="Acccount Type"
                            />
                        </Stack>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={8} lg={9}>
                    <TableContainer
                        minHeight={750}
                        action={
                            <Button
                                variant="outlined"
                                onClick={() => navigate('/customers/create')}
                                startIcon={<MuiIcon name="AddCircleOutline" />}
                            >
                                New Customer
                            </Button>
                        }
                        rows={customers}
                        columns={tableColumns(tableActionCallbacks)}
                        loading={loading}
                    />
                </Grid>
            </Grid>
        </DashboardPage>
    )
}

export default List
