import React, { useState } from 'react'
import Create from './create'
import List from './list'
import { Button, CardActions, Paper } from '@mui/material'

const Comments = ({ comments, title, handleUpdate, handleCreate, handleDelete }) => {
    const [createFormOpen, setCreateFormOpen] = useState(false)

    const createComment = async (data) => {
        const created = await handleCreate(data)

        if (created) {
            setCreateFormOpen(false)
        }
    }

    return (
        <Paper variant="outlined" sx={{ p: 2 }}>
            <List
                comments={comments}
                title={title}
                handleUpdate={handleUpdate}
                handleDelete={handleDelete}
            />

            <CardActions>
                <Button
                    size="small"
                    variant="text"
                    onClick={() => setCreateFormOpen(!createFormOpen)}
                >
                    Add Comment
                </Button>
                <Create
                    formOpen={createFormOpen}
                    toggleOpen={() => setCreateFormOpen(!createFormOpen)}
                    handleCreate={createComment}
                />
            </CardActions>
        </Paper>
    )
}

export default Comments
