import axios from 'axios'

const prefix = `api/jobs`

const jobs = {
    list: (params) => axios.get(`${prefix}/`, { params }),
    create: async (job) => axios.post(`${prefix}/`, job),
    read: async (job) => axios.get(`${prefix}/${job}`),
    addComment: async (comment) => axios.post(`${prefix}/${comment.jobId}/comments`, comment),
    updateComment: async (comment) =>
        axios.patch(`${prefix}/${comment.jobId}/comments/${comment.id}`, comment),
    deleteComment: async (comment) =>
        axios.delete(`${prefix}/${comment.jobId}/comments/${comment.id}`),
    updateTask: async (task) => axios.patch(`${prefix}/${task.jobId}/tasks/${task.id}`, task),
    query: async (data) => axios.post(`${prefix}/query`, data),
    update: async (job) => axios.patch(`${prefix}/${job.id}`, job),
    delete: async (job) => axios.delete(`${prefix}/${job}`),
}

export default jobs
