import React, { useEffect, useState } from 'react'
import { HashRouter as Router, Route, Routes } from 'react-router-dom'
import { baseAppRoutes, publicRoutes, tenantAppRoutes } from './constants'
import { useDispatch } from 'react-redux'
import { decodeAuthToken, hasSubDomain, setAuthToken, setAxiosDefaults } from 'lib/utilities'
import { setAuthUser } from 'app/redux/authentication/slice'
import Loading from 'lib/pages/Loading'

import PrivateRoute from './PrivateRoute'

const NavRoutes = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)

    const { authToken } = localStorage

    useEffect(() => {
        setAxiosDefaults(dispatch)
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (authToken) {
            setAuthToken(authToken)
            const user = decodeAuthToken(authToken)

            dispatch(setAuthUser(Date.now() <= user.exp * 1000 ? user : null))
            setAxiosDefaults(dispatch)
        }
        setLoading(false)
    }, [authToken, dispatch])

    if (loading) return <Loading />

    const renderRoutes = (routesList) => {
        return routesList.map(({ key, path, Page, requireAuth }) => {
            return (
                <Route
                    key={key}
                    path={path}
                    element={
                        requireAuth ? (
                            <PrivateRoute>
                                <Page />
                            </PrivateRoute>
                        ) : (
                            <Page />
                        )
                    }
                />
            )
        })
    }

    return (
        <Router>
            <Routes>
                {renderRoutes(publicRoutes)}

                {renderRoutes(hasSubDomain() ? tenantAppRoutes : baseAppRoutes)}
            </Routes>
        </Router>
    )
}

export default NavRoutes
