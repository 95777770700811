import React, { useEffect, useState } from 'react'
import { DashboardPage } from 'lib/components/layout/page'
import { useNavigate, useParams } from 'react-router-dom'
import RtlBox from 'lib/components/bits/RtlBox'
import { Box, Button, CardActions, Chip, Grid, Paper, Typography } from '@mui/material'
import { categories, getService } from '../constants'
import NothingToShow from 'lib/components/bits/NothingToShow'
import { SimpleKpi } from '@instantia/mui-kit.ui'

const Read = () => {
    const [service, setService] = useState(null)
    const { id } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        getService(id).then((data) => setService(data))
    }, [id])

    if (!service) return ''

    return (
        <DashboardPage title="Service">
            <RtlBox>
                <Button
                    onClick={() => navigate(`/services/${id}/update`)}
                    sx={{ ml: 1 }}
                    variant="outlined"
                >
                    Update Service
                </Button>
            </RtlBox>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4}>
                    <Paper variant="outlined" sx={{ p: 2 }}>
                        <Box sx={{ height: 168 }}>
                            <Typography variant="subtitle1">{service.name}</Typography>
                            <Typography>{service.description}</Typography>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    pt: 2,
                                }}
                            >
                                <Typography variant="subtitle1">Price</Typography>
                                <Typography>
                                    £ {service.unitPrice.toFixed(2)} / {service.unit}
                                </Typography>
                                <Typography varient="subtitle1">VAT</Typography>
                                <Chip size="small" label={'20%'} />
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                    <Grid container spacing={1}>
                        {categories.map((item) => (
                            <Grid item xs={12} sm={6} key={item.title}>
                                <SimpleKpi title={item.title} metric={item.metric} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                {['Quote', 'Job', 'Invoice'].map((table, index) => {
                    return (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Paper variant="outlined" sx={{ p: 2 }}>
                                <Typography variant="subtitle1">{`Service ${table}s`}</Typography>
                                <Box height={250}>
                                    <NothingToShow />
                                </Box>
                                <CardActions>
                                    <Button size="small" variant="text">
                                        View details
                                    </Button>
                                </CardActions>
                            </Paper>
                        </Grid>
                    )
                })}
            </Grid>
        </DashboardPage>
    )
}

export default Read
