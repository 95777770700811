import React from 'react'
import { Box } from '@mui/material'

const NothingToShow = () => (
    <Box
        sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        Nothing to show
    </Box>
)

export default NothingToShow
