export const tableColumns = (Actions) => {
    return [
        {
            field: 'customer',
            headerName: 'Customer',
            valueGetter: ({ row }) => row.customer.name || '',
            width: 180,
        },
        {
            field: 'vehicle',
            headerName: 'Vehicle',
            valueGetter: ({ row }) =>
                `${row.vehicle.registration} [${row.vehicle.make} ${row.vehicle.model}]`,
            width: 250,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 100,
        },
        {
            field: 'lines',
            headerName: '# Items',
            valueGetter: ({ row }) => row.lines.length,
            width: 75,
        },
        {
            field: 'total',
            headerName: 'Total',
            valueGetter: ({ row }) => `£ ${row.total || 0}`,
            width: 75,
        },
        {
            field: 'id',
            headerName: '',
            renderCell: ({ row }) => <Actions row={row} />,
            width: 120,
        },
    ]
}

export const categories = [
    {
        qId: '6455a4be9852c07f2cfda3f4',
        entity: 'queryStore',
        title: 'Quotes Total',
        prefix: '£',
    },
    {
        qId: '64564fda76f389b649816a2e',
        entity: 'queryStore',
        title: 'Quote Avg',
        prefix: '£',
    },
]
