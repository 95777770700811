import React, { useCallback, useEffect, useState } from 'react'
import { DashboardPage } from 'lib/components/layout/page'
import api from 'app/api'
import { useNavigate } from 'react-router-dom'
import { categories, tableColumns } from '../constants'
import { useDialog } from 'lib/context/Dialog'
import { toast } from 'react-toastify'
import { MuiAlert } from '@instantia/mui-kit.ui'
import TableContainer from 'lib/components/bits/TableContainer'
import { Grid } from '@mui/material'
import Kpi from 'lib/components/data/Kpi'
import { Stack } from '@mui/system'

const List = () => {
    const [loading, setLoading] = useState(true)
    const [jobs, setJobs] = useState([])
    const dialog = useDialog()

    const navigate = useNavigate()
    const getJobs = useCallback(() => {
        api({ entity: 'jobs', action: 'list' })
            .then(({ data, status }) => {
                if (status === 200) {
                    setJobs(data)
                }
            })
            .catch((err) => console.error(err))
            .finally(() => setLoading(false))
    }, [])

    const handleDeleteJob = async (id) => {
        setLoading(true)
        api({ entity: 'jobs', action: 'delete', data: id })
            .then(({ status }) => {
                if (status === 204) {
                    const updatedJobs = jobs.filter((job) => job.id !== id)
                    setJobs(updatedJobs)
                    toast.success('Job deleted successfully')
                }
            })
            .catch(() => toast.error('Failed to delete job'))
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getJobs()
    }, [getJobs])

    const tableActionCallbacks = (id) => {
        return {
            view: () => navigate(`/jobs/${id}/read`),
            delete: () => {
                dialog.setData({
                    title: 'Confirm Delete',
                    content:
                        'Deleting job can not be reversed. This will mark related quotes and invoices as cancelled. Are you sure you want to continue?',
                    handleConfirm: () => handleDeleteJob(id),
                })
                dialog.setOpen(true)
            },
        }
    }

    return (
        <DashboardPage title="Jobs">
            <MuiAlert
                severity="info"
                title="info"
                description="Jobs are generated from approved quotations."
                action={{
                    callback: () => navigate('/quotes'),
                    label: 'Quotes',
                }}
            />

            <Grid container spacing={1} sx={{ pb: 2 }}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Stack spacing={1}>
                        {categories.map((item, index) => (
                            <Kpi {...item} key={index} />
                        ))}
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={8} lg={9}>
                    <TableContainer
                        minHeight={650}
                        loading={loading}
                        columns={tableColumns(tableActionCallbacks)}
                        rows={jobs}
                    />
                </Grid>
            </Grid>
        </DashboardPage>
    )
}

export default List
