import SubtleCard from "lib/components/bits/SubtleCard";
import React from 'react'
import { Box, IconButton, List, ListItem, Typography } from '@mui/material'
import dayjs from 'dayjs'
import Update from '../update'
import { useDialog } from 'lib/context/Dialog'
import { MuiIcon } from '@instantia/mui-kit.ui'
import { Stack } from '@mui/system'

const CommentsList = ({ title, comments, handleUpdate, handleDelete }) => {
    const dialog = useDialog()

    const handleDeleteComment = (id) => {
        dialog.setData({
            title: 'Confirm Delete',
            content: 'Are you sure you would like to delete this comment.',
            handleConfirm: () => handleDelete(id),
        })

        dialog.setOpen(true)
    }

    return (
        <List>
            <ListItem>
                <Typography variant="overline">{title}</Typography>
            </ListItem>
            <ListItem>
                <Stack spacing={1} sx={{ width: '100%' }}>
                    {comments.map(({ id, comment, subject, created_at }) => (
                        <SubtleCard
                            key={id}
                            title={subject}
                            description={comment}
                            footer={{
                                text: dayjs(created_at).fromNow(),
                                actions:
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <Update
                                            comment={{ id, comment, subject }}
                                            handleUpdateComment={handleUpdate}
                                        />

                                        <IconButton onClick={() => handleDeleteComment(id)}>
                                            <MuiIcon name={'Delete'} />
                                        </IconButton>
                                    </Box>
                            }}
                         />
                    ))}
                </Stack>
            </ListItem>
        </List>
    )
}

export default CommentsList
