import starterBg from 'assets/images/gradient/halo/01.jpg'
import professionalBg from 'assets/images/gradient/halo/03.jpg'
import enterpriseBg from 'assets/images/gradient/halo/06.jpg'
import garageIsometric from '../../../../assets/images/isometric/garage.png'
import dataDrivenIsometric from '../../../../assets/images/isometric/automobile.png'

const starterItems = [
    'Users Management',
    'Customer Database',
    'Inventory Management',
    'Quotations & Invoicing',
    'Jobsheets',
]

const professionalItems = [
    ...starterItems,
    'Dynamic work Scheduling',
    'Payment Links',
    'Postcode Lookup',
    'Messaging',
]

const enterpriseItems = [...professionalItems, 'VRM Lookup', 'Reporting Suite', 'Remote Support']

export const monthlyPricingItems = [
    {
        title: 'Stater',
        image: starterBg,
        price: 'TBC',
        items: starterItems,
    },
    {
        title: 'Professional',
        image: professionalBg,
        price: 'TBC',
        items: professionalItems,
    },
    {
        title: 'Enterprise',
        image: enterpriseBg,
        price: 'TBC',
        items: enterpriseItems,
    },
]

export const chessGridContentRows = [
    {
        image: garageIsometric,
        icon: 'RocketLaunch',
        content: `Maneja is a data-driven garage management solution that helps 
            you gain visibility in your operations. Our cloud-based software 
            allows you to manage your garage from anywhere, at any time, on any device.`,
    },
    {
        image: dataDrivenIsometric,
        icon: 'AutoGraph',
        content: `With Garage Maneja's data-driven approach, you'll be able to see 
        which vehicles are taking the longest to repair, which mechanics are 
        performing the best, and which parts are in highest demand. 
        This information can help you optimize your operations and improve 
        your bottom line.`,
    },
]

export const moreFeatures = [
    {
        icon: 'CalendarToday',
        title: 'Scheduling and appointment management',
        content: `Our software lets you schedule appointments, assign jobs,
        and manage your mechanics' availability all in one place,
        making it easy to keep everyone on the same page.`,
    },
    {
        icon: 'AccessTime',
        title: 'Real-time updates',
        content: `  Real-time updates on the status of your vehicles: Garage
        Maneja allows you to track the status of each vehicle in
        real-time, so you can see when a vehicle has arrived,
        when it's being worked on, and when it's ready for pickup.`,
    },
    {
        icon: 'Inventory2',
        title: 'Automated inventory management',
        content: `Our software automatically updates your inventory as you
        use parts, so you always know what you have on hand and
        when you need to reorder.`,
    },
]
