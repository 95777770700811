import React, { useEffect, useState } from 'react'
import { categories, tableColumns } from '../constants'
import api from 'app/api'
import { Button, Grid, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useDialog } from 'lib/context/Dialog'
import { toast } from 'react-toastify'
import { DashboardPage } from 'lib/components/layout/page'
import TableContainer from 'lib/components/bits/TableContainer'
import { SimpleKpi } from '@instantia/mui-kit.ui'

const List = () => {
    const navigate = useNavigate()
    const dialog = useDialog()
    const [expenses, setCategories] = useState([])
    const [loading, setLoading] = useState(true)

    const getExpenseCategories = () => {
        api({ entity: 'expenses', action: 'list' })
            .then(({ data }) => {
                setCategories(data)
            })
            .catch((err) => {
                console.error(err)
            })
            .finally(() => setLoading(false))
    }

    const handleDeleteExpenseCategory = (id) => {
        setLoading(true)
        api({
            entity: 'expenses',
            action: 'delete',
            data: id,
        })
            .then(({ status }) => {
                if (status === 204) {
                    const _expenses = expenses.filter((item) => item.id !== id)
                    setCategories(_expenses)
                    toast.success('Expense category deleted')
                }
            })
            .catch((err) => {
                console.error(err)
                toast.success('Error deleting expense category.')
            })
            .finally(() => setLoading(false))
    }

    const tableActionCallbacks = (id) => {
        return {
            update: () => navigate(`/settings/expenses/${id}/update`),
            delete: () => {
                dialog.setData({
                    title: 'Confirm Delete',
                    content:
                        'Deleting expense category can not be reversed. Are you sure you want to continue',
                    handleConfirm: () => handleDeleteExpenseCategory(id),
                })
                dialog.setOpen(true)
            },
        }
    }

    useEffect(() => {
        getExpenseCategories()
    }, [])

    return (
        <DashboardPage title="Expenses">
            <Stack spacing={1}>
                <Grid spacing={2} container>
                    {categories.map((item) => (
                        <Grid item xs={12} sm={4} key={item.title}>
                            <SimpleKpi title={item.title} metric={item.metric} />
                        </Grid>
                    ))}
                </Grid>
                <TableContainer
                    minHeight={550}
                    action={
                        <Button variant="outlined" onClick={() => navigate('create')}>
                            New Expense
                        </Button>
                    }
                    columns={tableColumns(tableActionCallbacks)}
                    rows={expenses}
                    loading={loading}
                />
            </Stack>
        </DashboardPage>
    )
}

export default List
