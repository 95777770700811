import * as Yup from 'yup'

export const formFields = [
    {
        component: 'MuiSelectField',
        name: 'status',
        label: 'Status',
        options: [
            {
                value: 'not started',
                label: 'Not Started',
            },
            {
                value: 'started',
                label: 'Started',
            },
            {
                value: 'completed',
                label: 'Complete',
            },
            {
                value: 'Cancelled',
                label: 'Cancel',
            },
        ],
    },
    {
        component: 'MuiTextField',
        name: 'description',
        label: 'Description/Notes',
        multiline: true,
        rows: 5,
    },
]

export const validationSchema = Yup.object().shape({
    description: Yup.string().nullable(),
    status: Yup.string().required('Select status'),
})
