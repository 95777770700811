import { Box, Button, Paper, Typography } from '@mui/material'
import SubtleCard from 'lib/components/bits/SubtleCard'
import React from 'react'


const statusList = {
    'not started': {
        description: 'Job not started, update tasks to get started',
    },
    started: {
        description: 'Job started, complete all tasks before the job can be completed',
    },
    'partially completed': {
        description: 'Almost there!, Set all tasks to completed before the job can be invoiced',
        footer: {
            text: 'Complete all tasks',
            action: { update : 'completed', label: 'Complete Job'}
        },
    },
    completed: {
        description: 'All done!, Invoice automatically generated',
    },
    cancelled: {
        description: 'All done!, Invoice automatically generated',
    },
}

const JobStatus = ({ job, handleUpdate }) => {
    const status = statusList[job.status]

    if (!status) return null

    const actions = () => {
        if(!status.footer) return null
        if(!status.footer.action) return null
        const {label, update} = !status.footer.action
        return <Button onClick={() => handleUpdate(update)} variant='link'>{label}</Button>
    }

    const footer = {
        text: status?.footer?.text,
        actions: actions()
    }


    return (
        <Paper variant="outlined" sx={{ p: 3 }}>
            <Box sx={{ pb: 2 }}>
                <Typography variant="overline">Job Status</Typography>
            </Box>
            <SubtleCard
                title={job.status}
                description={status.description}
                footer={footer}
            />
        </Paper>
    )
}

export default JobStatus
