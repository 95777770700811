import { FeIcon } from '@instantia/mui-kit.ui'
import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import intro from 'assets/images/isometric/workshop-laptop_tp.png'
import { pages } from 'constants'
import { DashboardPage } from 'lib/components/layout/page'
import PropTypes from 'prop-types'
import React from 'react'
import {useNavigate} from "react-router-dom";
import palette from 'theme/palette'
import useStyles from 'theme/styles'

const Dashboard = () => {
    const styles = useStyles()
    const navigate = useNavigate()
    const renderGrid = (page, index) => {
        if (!page.description) return

        return (
            <Paper variant="outlined" sx={{ p: 2 }} key={index}>
                <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'
                >
                    <FeIcon name={page.icon} />

                    <Typography variant="subtitle1" onClick={() => navigate(page.path)}>
                        {page.title}
                    </Typography>
                </Box>
            </Paper>
        )
    }

    return (
        <DashboardPage>
            <Box sx={{ width: '100%', pb: 2 }}>
                <Stack spacing={1}>
                    <Typography variant={'h4'}>Getting Started</Typography>
                    <Typography variant={'caption'}>
                        Let us manage the database engines for your application so you can focus on
                        vehicles.
                    </Typography>
                </Stack>
            </Box>

            <Grid container spacing={1}>
                <Grid item sm={12} md={8}>
                    <Paper
                        variant="outlined"
                        sx={{
                            p: 2,
                            height: '425px',
                            backgroundColor: palette.grey[200],
                            // backgroundImage: 'linear-gradient(to right, #dae1ea, #ffffff, #dae1ea)',

                            border: 'none',
                        }}
                    >
                        <Box
                            className={styles.rowSpaceBetween}
                            sx={{ height: '100%', opacity: '0.9' }}
                        >
                            <Box className={styles.columnLeftContent} sx={{ height: '100%' }}>
                                <Typography variant={'h4'}>Introduction to Maneja</Typography>
                                <Typography sx={{ pt: 2 }}>
                                    Lollipop chocolate marzipan marshmallow gummi bears. Tootsie
                                    roll liquorice cake jelly beans.
                                </Typography>

                                <Box width={'70%'} sx={{ pt: 2 }}>
                                    <Button
                                        startIcon={<FeIcon name={'ChevronRight'} />}
                                        variant="outlined"
                                        size={'small'}
                                    >
                                        Getting Started
                                    </Button>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    zIndex: -1,
                                    width: '60%',
                                    height: '100%',
                                    backgroundImage: `url(${intro})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center center',
                                }}
                            >
                                {' '}
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item sm={12} md={4}>
                    <Stack spacing={1}>{pages.map((page, index) => renderGrid(page, index))}</Stack>
                </Grid>
            </Grid>
        </DashboardPage>
    )
}

Dashboard.propTypes = {
    title: PropTypes.string,
}

Dashboard.defaultProps = {
    title: '',
}

export default Dashboard
