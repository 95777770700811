import axios from 'axios'

const prefix = `api/expense-categories`

const expenseCategories = {
    list: (params) => axios.get(`${prefix}`, { params }),
    create: async (category) => axios.post(`${prefix}/`, category),
    read: async (category) => axios.get(`${prefix}/${category}`),
    query: async (data) => axios.post(`${prefix}/query`, data),

    update: async (category) => axios.patch(`${prefix}/${category.id}`, category),
    delete: async (category) => axios.delete(`${prefix}/${category}`),
}

export default expenseCategories
