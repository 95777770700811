import axios from 'axios'

const prefix = `api/expenses`

const expenses = {
    list: (params) => axios.get(`${prefix}/`, { params }),
    create: async (expense) => axios.post(`${prefix}/`, expense),
    read: async (expense) => axios.get(`${prefix}/${expense}`),
    query: async (data) => axios.post(`${prefix}/query`, data),
    update: async (expense) => axios.patch(`${prefix}/${expense.id}`, expense),
    delete: async (expense) => axios.delete(`${prefix}/${expense}`),
}

export default expenses
