import React, { useCallback, useEffect, useState } from 'react'
import { DashboardPage } from 'lib/components/layout/page'
import { useLocation, useParams } from 'react-router-dom'
import api from 'app/api'
import { generateBreadcrumbs } from 'lib/utilities'
import { Box, Button, Chip, Container, Grid, Paper, Stack, Typography } from '@mui/material'
import AddressBox from 'lib/components/bits/AddressBox'
import dayjs from 'dayjs'
import PricingFooter from 'lib/components/bits/PricingFooter'
import { QuotesLinesList } from './lines'
import RtlBox from 'lib/components/bits/RtlBox'
import { useDialog } from 'lib/context/Dialog'
import { upperFirst } from 'lodash'
import { toast } from 'react-toastify'

const statusList = {
    pending: {
        color: 'default',
    },
    accepted: {
        color: 'primary',
        content:
            'Are you sure you would like mark this quote as accepted? This will generate a new job in the jobs section attached to the quote.',
    },
    declined: {
        color: 'danger',
        content:
            'Are you sure you would like mark this quote as declined? Once declined this quote can not be re-opened',
    },
    completed: { color: 'success' },
}

const Read = () => {
    const dialog = useDialog()
    const [quote, setQuote] = useState(null)
    const [services, setServices] = useState([])
    const { id } = useParams()
    const { pathname } = useLocation()
    const entity = 'quotes'

    const getQuote = useCallback(() => {
        api({ entity, action: 'read', data: id })
            .then(({ data }) => {
                setQuote(data)
            })
            .catch((err) => {
                console.error('failed to retrieve quote', err)
            })
    }, [id])

    const getServices = useCallback(() => {
        api({ entity: 'services', action: 'list' })
            .then(({ data }) => {
                setServices(data)
            })
            .catch((err) => console.error('failed to retrieve quote', err))
    }, [])

    useEffect(() => {
        getQuote()
        getServices()
    }, [getQuote, getServices])

    useEffect(() => {
        if (quote) {
        }
    }, [quote])

    const breadcrumbs = generateBreadcrumbs({
        icon: 'WorkOutline',
        name: 'Quotes List',
        pathname,
        action: 'view',
        label: 'quote',
    })

    const updateQuoteStatus = (status) => {
        api({ entity, action: 'update', data: { id, status } })
            .then(({ data }) => {
                toast.success('Quote status updated successfully')
                setQuote(data)
            })
            .catch((err) => {
                console.error('failed to update quote', err)
            })
    }

    const updateStatusDialog = (status) => {
        const title = upperFirst(`Confirm Quote ${status}`)
        const { content } = statusList[status]

        dialog.setData({
            title,
            content,
            handleConfirm: () => updateQuoteStatus(status),
        })
        dialog.setOpen(true)
    }

    if (!quote) return 'Please Wait'

    return (
        <DashboardPage title={'Quotes'} breadcrumbs={breadcrumbs}>
            <Container>
                <Box sx={{ ml: -2, maxWidth: 300 }}>
                    <AddressBox
                        name={quote.customer.name}
                        address={quote.customer.address}
                        outlined={false}
                    />
                </Box>
                <Stack spacing={2}>
                    <Grid container>
                        <Grid item xs={10}>
                            <Box>
                                <Typography variant={'subtitle1'}>Date</Typography>
                                <Typography>{dayjs(quote.date).format('DD/MM/YYYY')}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <RtlBox>
                                <Chip
                                    variant="outlined"
                                    label={upperFirst(quote.status)}
                                    color={statusList[quote.status].color}
                                    size="small"
                                />
                            </RtlBox>
                        </Grid>
                    </Grid>

                    <Paper variant="outlined" sx={{ p: 2 }}>
                        <Typography variant={'subtitle1'}>Notes</Typography>
                        <Typography>{quote.notes || 'Nothing to show'}</Typography>
                    </Paper>

                    <QuotesLinesList
                        quote={quote}
                        lines={quote.lines}
                        services={services}
                        setQuote={setQuote}
                    />

                    <RtlBox>
                        <PricingFooter {...quote} />
                    </RtlBox>

                    {quote.status === 'pending' && (
                        <RtlBox sx={{ height: 60 }}>
                            <Button
                                onClick={() => updateStatusDialog('accepted')}
                                sx={{ mr: 1, ml: 1 }}
                            >
                                Accepted
                            </Button>
                            <Button
                                onClick={() => updateStatusDialog('declined')}
                                variant="outlined"
                                sx={{ mr: 1, ml: 1 }}
                            >
                                Declined
                            </Button>
                        </RtlBox>
                    )}
                </Stack>
            </Container>
        </DashboardPage>
    )
}

export default Read
