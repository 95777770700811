import React from 'react'
import { FormBuilder, MuiBreadcrumbs } from '@instantia/mui-kit.ui'
import { formFields, validationSchema, initialValues } from '../constants'
import { PageContainer } from 'lib/components/layout/page'
import { Box } from '@mui/material'
import { FormContainer } from 'lib/components/layout/form'
import api from 'app/api'
import { useNavigate } from 'react-router-dom'

const Create = () => {
    const navigate = useNavigate()
    const handleCreateTenant = async (data) => {
        const { status } = await api({
            entity: 'tenants',
            action: 'create',
            data,
        }).catch((err) => console.error(err))

        if (status === 204) {
            navigate('/tenants')
        }
    }

    return (
        <PageContainer>
            <MuiBreadcrumbs
                items={[
                    { label: 'Home', icon: 'Home', link: '/#/' },
                    { label: 'Tenants', icon: 'Apps', link: '/#/tenants' },
                    { label: 'Create' },
                ]}
            />

            <FormContainer>
                <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
                    <FormBuilder
                        initialValues={initialValues}
                        handleSubmit={handleCreateTenant}
                        validationSchema={validationSchema}
                        formFields={formFields}
                        title={'Create Tenant'}
                        submitButtonText={'Create Tenant'}
                    />
                </Box>
            </FormContainer>
        </PageContainer>
    )
}

export default Create
