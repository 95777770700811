import axios from 'axios'

const prefix = `api/customers`

const customers = {
    list: (params) => axios.get(`${prefix}/`, { params }),
    create: async (customer) => axios.post(`${prefix}/`, customer),
    read: async (customer) => axios.get(`${prefix}/${customer}`),
    query: async (data) => axios.post(`${prefix}/query`, data),
    update: async (customer) => axios.patch(`${prefix}/${customer.id}`, customer),
    delete: async (customer) => axios.delete(`${prefix}/${customer}`),
}

export default customers
