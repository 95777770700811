import React from 'react'
import { FormBuilder, MuiDrawer } from '@instantia/mui-kit.ui'
import { formFields, initialValues, validationSchema } from '../constants'
import { useParams } from 'react-router-dom'

const Create = ({ handleCreateVehicle }) => {
    const { id } = useParams()

    return (
        <MuiDrawer
            radius={2}
            trigger={{
                element: 'IconButton',
                icon: 'Add',
            }}
        >
            <FormBuilder
                initialValues={{
                    customer: id,
                    ...initialValues,
                }}
                formFields={formFields}
                handleSubmit={handleCreateVehicle}
                validationSchema={validationSchema}
                submitButtonText="Create Customer Vehicle"
                title="New Vehicle"
            />
        </MuiDrawer>
    )
}

export default Create
