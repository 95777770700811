import React, { useState } from 'react'
import { FormBuilder, MuiDrawer } from '@instantia/mui-kit.ui'
import { formFields, validationSchema } from './constants'
import api from 'app/api'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'

const Create = ({ services, setQuote }) => {
    const [open, setOpen] = useState(false)
    const entity = 'quotes'
    const { id } = useParams()

    const handleAddQuoteLine = (line) => {
        api({
            entity,
            action: 'addLine',
            data: {
                quote: id,
                ...line,
            },
        })
            .then(({ data }) => {
                setQuote(data)
                setOpen(false)
            })
            .catch((err) => {
                console.error(err)
                toast.error('Failed to add quote line')
            })
    }

    return (
        <MuiDrawer
            radius={2}
            open={open}
            handleOpen={() => setOpen(true)}
            trigger={{
                element: 'IconButton',
                icon: 'Add',
            }}
            title={'New Item Line'}
        >
            <FormBuilder
                title="New Quote Line"
                initialValues={{
                    service: '',
                    charged: '',
                    description: '',
                    vat: false,
                }}
                validationSchema={validationSchema}
                formFields={formFields(services)}
                submitButtonText={'Add Quote Line'}
                handleSubmit={handleAddQuoteLine}
            />
        </MuiDrawer>
    )
}

export default Create
