import React, { useState } from 'react'
import { tableColumns } from '../constants'
import tableActionButtons from 'lib/components/bits/TableActionButtons'
import { useNavigate } from 'react-router-dom'
import { useDialog } from 'lib/context/Dialog'
import TableContainer from 'lib/components/bits/TableContainer'
import { QuoteForm } from 'lib/components/forms'
import { setCustomerEntity } from 'app/redux/customer/slice'
import { useDispatch } from 'react-redux'
import api from 'app/api'
import { toast } from 'react-toastify'

const List = ({ quotes }) => {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const dialog = useDialog()
    const dispatch = useDispatch()
    const entity = 'quotes'

    const handleCreateQuote = (quote) => {
        const _quotes = quotes.concat(quote)
        dispatch(setCustomerEntity({ entity, data: _quotes }))
    }

    const handleUpdateQuote = (quote) => {
        setLoading(true)
        const _quotes = quotes.map((item) => {
            if (item.id === quote.id) return quote
            return item
        })

        dispatch(setCustomerEntity({ entity, data: _quotes }))
        setLoading(false)
    }

    const handleDeleteQuote = (_quote) => {
        api({ entity: 'quotes', action: 'delete', data: _quote }).then(({ status }) => {
            if (status === 204) {
                const updated = quotes.filter((item) => _quote !== item.id)
                dispatch(setCustomerEntity({ entity, data: updated }))
                toast.success('Remove quote successfully')
            }
        })
    }

    const Actions = ({ row }) =>
        tableActionButtons({
            title: 'Quotes',
            actions: {
                view: () => navigate(`/quotes/${row.id}/read`),
                update: {
                    Element: (
                        <QuoteForm
                            key={'update'}
                            submitCallback={handleUpdateQuote}
                            quote={row}
                            action={'update'}
                        />
                    ),
                },
                delete: () => {
                    dialog.setData({
                        title: 'Confirm Delete',
                        content:
                            'Deleting quote can not be reversed. Are you sure you want to continue?',
                        handleConfirm: () => handleDeleteQuote(row.id),
                    })
                    dialog.setOpen(true)
                },
            },
        })

    return (
        <TableContainer
            getRowId={(row) => row.id}
            loading={loading}
            minHeight={320}
            title="Quotes"
            action={<QuoteForm submitCallback={handleCreateQuote} action={'create'} />}
            columns={tableColumns(Actions)}
            rows={quotes}
        />
    )
}

export default List
