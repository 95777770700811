import { createTheme } from '@mui/material/styles'
import palette from './palette'
import typography from './typography'
import overrides from './overrides'
import MuiStyles from './styles'
import components from './components'
import shadows from './shadows'

const theme = createTheme({
    palette,
    typography,
    overrides,
    shadows,
    components,
    props: {
        MuiTypography: {
            variantMapping: {
                h1: 'h1',
                h2: 'h2',
                h3: 'h3',
                h4: 'h4',
                h5: 'h5',
                h6: 'h6',
                subtitle1: 'h3',
                subtitle2: 'h5',
                body1: 'p',
                body2: 'span',
            },
        },
    },
    spacing: 8,
    shape: { borderRadius: 8 },
})

export { theme, MuiStyles }
