import React from 'react'
import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { MuiTextField } from '@instantia/mui-kit.ui'
import useStyles from 'theme/styles'
import PropTypes from 'prop-types'

const RegisterInterest = ({ id }) => {
    const { centerContent } = useStyles()
    return (
        <Container
            id={id}
            sx={{
                pt: 4,
                pb: 3,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    maxWidth: 800,
                }}
            >
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={8} lg={9}>
                        <MuiTextField
                            name="mui-text-field"
                            variant="outlined"
                            size="small"
                            fullWidth
                            // helperText="Text Input Field"
                            label="Company Email"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ p: 1 }}>
                        <Button variant="outlined" size="medium" fullWidth>
                            Register Your Interest
                        </Button>
                    </Grid>

                    <Grid item xs={12} sm={6} md={8} lg={9}>
                        <Box className={centerContent}>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontWeight: 100,
                                    fontSize: '0.7em',
                                    textAlign: 'center',
                                }}
                            >
                                By submitting your email, you agree to our Privacy Policy, and that
                                Maneja can send you communications about its products and services.
                                You can unsubscribe at any time.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

RegisterInterest.propTypes = {
    id: PropTypes.string,
}
RegisterInterest.defaultProps = {
    id: '',
}

export default RegisterInterest
