import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import api from 'app/api'
import { Pie as MuiPie } from '@instantia/mui-kit.ui'

const Pie = ({ dimension, measures, filters, entity, title }) => {
    const [data, setData] = useState(null)

    const loadPieData = async () => {
        const res = await api({ entity, action: 'query', data: { dimension, measures, filters } })
        const _data = res.data.map((item) => {
            return { ...item, name: item._id }
        })
        setData(_data)
    }

    useEffect(() => {
        loadPieData()
        // eslint-disable-next-line
    }, [])

    if (!data) return 'loading...'

    return (
        <MuiPie
            title={title}
            isDonut={true}
            data={data}
            measure={'metric'}
            dimension={'_id'}
            height={250}
            showLegend={true}
        />
    )
}

Pie.propTypes = {
    dimension: PropTypes.string,
    measures: PropTypes.shape({}),
    filter: PropTypes.shape({}),
    entity: PropTypes.string.isRequired,
    title: PropTypes.string,
}

Pie.defaultProps = {
    dimension: null,
    measures: {},
    filters: {},
    title: null,
}
export default Pie
