import React from 'react'
import { Box, Grid, List, ListItem, Paper, Typography } from '@mui/material'
import AddressBox from 'lib/components/bits/AddressBox'
import { Stack } from '@mui/system'
import useStyles from 'theme/styles'

const CustomerDetails = ({ customer }) => {
    const styles = useStyles()
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6}>
                <Paper variant="outlined" style={{ minHeight: '200px' }}>
                    <List>
                        <ListItem>
                            <Typography variant={'overline'}>{customer.name}</Typography>
                        </ListItem>
                        <ListItem>
                            <Stack sx={{ width: '100%' }}>
                                <Box className={styles.rowSpaceBetween}>
                                    <Typography>Email</Typography>
                                    <Typography>{customer.email}</Typography>
                                </Box>
                                <Box className={styles.rowSpaceBetween}>
                                    <Typography>Phone</Typography>
                                    <Typography>{customer.phone}</Typography>
                                </Box>
                                <Box className={styles.rowSpaceBetween}>
                                    <Typography>Account Type</Typography>
                                    <Typography>{customer.account}</Typography>
                                </Box>
                                <Box className={styles.rowSpaceBetween}>
                                    <Typography>Payment Terms</Typography>
                                    <Typography>{customer.paymentTerms}</Typography>
                                </Box>
                            </Stack>
                        </ListItem>
                    </List>
                </Paper>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
                <Paper variant="outlined" style={{ height: '200px' }}>
                    <AddressBox name={'Address'} outlined={false} />
                </Paper>
            </Grid>
        </Grid>
    )
}
export default CustomerDetails
