import React from 'react'
import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const AmountRow = ({ title, amount }) => {
    return (
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} height={30}>
            <Typography variant={'subtitle1'}>{title}</Typography>
            <Typography variant={'body1'}>£ {amount}</Typography>
        </Box>
    )
}

const PricingFooter = ({ subTotal, vatTotal, total }) => (
    <Box sx={{ pt: 3 }} width={250}>
        <AmountRow title={'Sub Total'} amount={subTotal?.toFixed(2) || 0.0} />
        <AmountRow title={'VAT'} amount={vatTotal?.toFixed(2) || 0.0} />
        <AmountRow title={'Total'} amount={total?.toFixed(2) || 0.0} />
    </Box>
)

PricingFooter.propTypes = {
    subTotal: PropTypes.number,
    vatTotal: PropTypes.number,
    total: PropTypes.number,
}

PricingFooter.defaultProps = {
    total: 0,
    vatTotal: 0,
    subTotal: 0,
}

export default PricingFooter
