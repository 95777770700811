import TableActionButtons from 'lib/components/bits/TableActionButtons'
import * as Yup from 'yup'
import dayjs from 'dayjs'
import { makeDropDownOptions } from 'lib/utilities'
import api from '../../api'

export const categories = [
    {
        title: 'Expense Invoices',
        metric: '300',
    },
    {
        title: 'Expenses Total',
        metric: '£ 12900.33',
    },
    {
        title: 'Avg Invoice Total',
        metric: '£ 43.33',
    },
]

export const tableColumns = (actions) => [
    {
        field: 'number',
        headerName: 'Invoice #',
        sortable: true,
        width: 180,
    },
    {
        field: 'details',
        headerName: 'Details',
        sortable: true,
        width: 300,
    },
    {
        field: 'category',
        headerName: 'Category',
        sortable: true,
        valueGetter: ({ row }) => `£ ${row.category.name}`,
        width: 150,
    },
    {
        field: 'total',
        headerName: 'Total',
        sortable: true,
        valueGetter: ({ row }) => `£ ${row.total.toFixed(2)}`,
        width: 100,
    },
    {
        field: 'date',
        headerName: 'Invoice Date',
        valueGetter: ({ row }) => dayjs(row.date).format('DD MMM YYYY'),
        sortable: true,
        width: 150,
    },
    {
        field: 'id',
        headerName: '',
        width: 100,
        renderCell: ({ row }) => (
            <TableActionButtons title={'customer'} actions={actions(row.id)} />
        ),
    },
]

export const formFields = (suppliers, categories) => {
    return [
        {
            component: 'MuiTextField',
            name: 'amount',
            label: 'Sub Total (Pre VAT)',
            size: { sm: 6, md: 6 },
        },
        {
            component: 'MuiTextField',
            name: 'vat',
            label: 'Invoice Vat',
            size: { sm: 6, md: 6 },
        },
        {
            component: 'MuiTextField',
            name: 'total',
            label: 'Invoice Total',
            size: { sm: 6, md: 6 },
        },

        {
            component: 'MuiTextField',
            name: 'number',
            label: 'Supplier Invoice Number',
            size: { sm: 6, md: 6 },
        },

        {
            component: 'MuiDateField',
            name: 'date',
            label: 'Supplier Invoice Date ',
            size: { sm: 6, md: 6 },
        },
        {
            component: 'MuiCheckboxField',
            name: 'paid',
            label: 'Paid',
            size: { sm: 6, md: 6 },
        },
        {
            component: 'MuiSelectField',
            name: 'supplier',
            label: 'Supplier',
            options: makeDropDownOptions(suppliers, 'name', 'id'),
            size: { sm: 6, md: 6 },
        },
        {
            component: 'MuiSelectField',
            name: 'purchaseCategory',
            label: 'Purchase Category',
            options: makeDropDownOptions(categories, 'name', 'id'),
            size: { sm: 6, md: 6 },
        },
        {
            component: 'MuiTextField',
            name: 'warranty',
            label: 'Warranty',
        },
        {
            component: 'MuiTextField',
            name: 'details',
            multiline: true,
            rows: 2,
            label: 'Purchase Invoice Details',
        },
    ]
}

export const validationSchema = Yup.object().shape({
    details: Yup.string().required('Required'),
    warranty: Yup.string(),
    amount: Yup.number().required('Required'),
    vat: Yup.number(),
    total: Yup.number().required('Required'),
    number: Yup.string().required('Required'),
    date: Yup.string().required('Required'),
    supplier: Yup.string().required('Required'),
    purchaseCategory: Yup.string().required('Required'),
})

export const getSuppliers = async () => {
    const { data } = await api({ entity: 'suppliers', action: 'list' }).catch((err) =>
        console.error(err)
    )
    return data
}
export const getCategories = async () => {
    const { data } = await api({ entity: 'expenseCategories', action: 'list' }).catch((err) =>
        console.error(err)
    )
    return data
}
