import * as Yup from 'yup'
import Update from './update'
import RtlBox from 'lib/components/bits/RtlBox'

export const initialValues = {
    make: '',
    model: '',
    registration: '',
    fuel: 'petrol',
    transmission: 'manual',
}

export const tableColumns = (Actions, handleUpdateVehicle) => {
    return [
        {
            field: 'registration',
            headerName: 'Reg',
            width: 120,
        },
        {
            field: 'make',
            headerName: 'Make',
            width: 150,
        },
        {
            field: 'model',
            headerName: 'Model',
            width: 150,
        },
        {
            field: 'id',
            headerName: '',
            width: 100,
            renderCell: ({ row }) => (
                <RtlBox>
                    <Update vehicle={row} handleUpdateVehicle={handleUpdateVehicle} />
                    <Actions row={row} />
                </RtlBox>
            ),
        },
    ]
}

export const formFields = [
    { name: 'make', label: 'Make', component: 'MuiTextField' },
    { name: 'model', label: 'Model', component: 'MuiTextField' },
    {
        name: 'registration',
        label: 'Reg',
        component: 'MuiTextField',
    },
    {
        name: 'fuel',
        label: 'Fuel Type',
        component: 'MuiSelectField',
        options: [
            { label: 'Petrol', value: 'petrol' },
            {
                label: 'Diesel',
                value: 'diesel',
            },
            { label: 'Hybrid', value: 'hybrid' },
            { label: 'Electric', value: 'electric' },
        ],
    },
    {
        name: 'transmission',
        label: 'Transmission',
        component: 'MuiSelectField',
        options: [
            { label: 'Manual', value: 'manual' },
            {
                label: 'Automatic',
                value: 'automatic',
            },
            { label: 'Semi Auto', value: 'semi-auto' },
        ],
    },
]
export const validationSchema = Yup.object().shape({
    make: Yup.string().required(),
    model: Yup.string().required(),
    registration: Yup.string().required(),
})
