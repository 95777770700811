import dayjs from 'dayjs'
import TableActionButtons from 'lib/components/bits/TableActionButtons'
import * as Yup from 'yup'
import 'yup-phone'
import api from '../../api'
import { toast } from 'react-toastify'

export const categories = [
    {
        title: 'Invoices',
        metric: '40',
    },
    {
        title: 'Quotes',
        metric: '55',
    },
    {
        title: 'Revenue',
        metric: '£ 340.98',
    },
    {
        title: 'Jobs',
        metric: '50',
    },
]

export const tableColumns = (actions) => {
    return [
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 300,
        },
        {
            field: 'unitPrice',
            headerName: 'Pricing',
            width: 150,
            valueGetter: ({ row }) => `£ ${row.unitPrice} / ${row.unit}`,
        },
        {
            field: 'created_at',
            headerName: 'Created Date',
            width: 150,
            valueGetter: ({ row }) => `${dayjs(row.created_at).fromNow()}`,
        },
        {
            field: 'id',
            headerName: 'Actions',
            width: 200,
            renderCell: ({ row }) => (
                <TableActionButtons title={'service'} actions={actions(row.id)} />
            ),
        },
    ]
}

export const formFields = [
    {
        component: 'MuiTextField',
        name: 'name',
        label: 'Name',
    },
    {
        component: 'MuiTextField',
        name: 'description',
        label: 'Description',
        multiline: true,
        rows: 5,
    },
    {
        component: 'MuiTextField',
        name: 'unitPrice',
        label: 'Unit Price (£)',
    },
    {
        component: 'MuiSelectField',
        name: 'unit',
        label: 'Unit measure',
        options: [
            { label: 'Single', value: 'item' },
            { label: 'Hourly', value: 'hourly' },
            { label: 'Flat Rate', value: 'service' },
        ],
    },
    {
        component: 'MuiCheckboxField',
        name: 'chargeVat',
        label: 'Add VAT (20%)',
    },
]

export const validationSchema = Yup.object().shape({
    name: Yup.string().required('Service Name is required'),
    description: Yup.string().required('Service Description is required').min(32),
    unitPrice: Yup.number(),
    unit: Yup.string(),
})

export const getService = async (id) => {
    const res = await api({ entity: 'services', action: 'read', data: id }).catch((err) => {
        console.error(err)
        toast.error('Error retrieving service')
    })

    if (res.status === 200) {
        return res.data
    }
}
