import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid, IconButton, Link, Paper } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import PageContainer from 'lib/components/layout/page/Container'
import api from 'app/api'
import { MuiBreadcrumbs, MuiIcon } from '@instantia/mui-kit.ui'
import { getTenantUrl } from 'lib/utilities'

const tenantContainerStyle = {
    p: 2,
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textTransform: 'uppercase',
}

const breadcrumbItems = [
    {
        label: 'Home',
        icon: 'Home',
        link: '/#/',
    },
    {
        label: 'Tenants',
        icon: 'Apps',
        link: '/#/tenants',
    },
]

const Tenants = () => {
    const [loading, setLoading] = useState(true)
    const [tenants, setTenants] = useState(null)

    const navigate = useNavigate()

    const getTenants = useCallback(() => {
        api({
            entity: 'tenants',
            action: 'list',
        })
            .then(({ data }) => {
                setTenants(data)
            })
            .catch((err) => {
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        getTenants()
    }, [getTenants])

    return (
        <PageContainer loading={loading}>
            <Box sx={{ ml: 4 }}>
                <MuiBreadcrumbs items={breadcrumbItems} />
            </Box>

            <Box sx={{ pt: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Paper variant={'outlined'} sx={{ p: 2 }}>
                            <Box sx={tenantContainerStyle}>
                                <Box p={1}>
                                    <Link target={'_self'} href={'/#/tenants/create'}>
                                        New Garage
                                    </Link>
                                </Box>
                                <Box p={1}>
                                    <IconButton onClick={() => navigate('/tenants/create')}>
                                        <MuiIcon name={'Add'} />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>

                    {tenants?.map(({ name, domain, id }) => {
                        const tenantDomain = getTenantUrl(domain)
                        return (
                            <Grid item xs={12} sm={4} key={id}>
                                <Paper variant="outlined" sx={{ p: 2 }}>
                                    <Box sx={tenantContainerStyle}>
                                        <Box p={1}>
                                            <Link
                                                target={'_blank'}
                                                href={`${tenantDomain}/#/sign-in`}
                                            >
                                                {name}
                                            </Link>
                                        </Box>
                                        <Box p={1}>
                                            <IconButton>
                                                <MuiIcon name={'Edit'} />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
        </PageContainer>
    )
}

export default Tenants
