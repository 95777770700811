import React, { useEffect, useState } from 'react'
import { FormBuilder, MuiDrawer } from '@instantia/mui-kit.ui'
import { formFields, validationSchema } from './constants'
import { useSelector } from 'react-redux'
import { customerSelector } from 'app/redux/selectors'
import api from 'app/api'
import { startCase } from 'lodash'

const entity = 'quotes'
const QuoteForm = ({ submitCallback, action, quote }) => {
    const [open, setOpen] = useState(false)
    const title = startCase(`${action} Quote`)
    const [initialValues, setInitialValues] = useState({})
    const customer = useSelector(customerSelector)
    const { vehicles, details } = customer
    const handleSubmit = ({ id, vehicle, date, notes, customer }) => {
        let _quote = { vehicle, date, notes, customer }
        if (action === 'update') {
            _quote = { ..._quote, id }
        }

        api({
            entity,
            action,
            data: _quote,
        }).then(({ data }) => {
            submitCallback(data)
            setOpen(false)
        })
    }

    const initialLoad = () => {
        if (action === 'create') {
            setInitialValues({
                name: '',
                vehicle: '',
                customer: details.id,
                quoteDate: new Date(),
                notes: '',
            })
        } else {
            const customer = quote.customer.id
            const vehicle = quote.vehicle.id
            setInitialValues({ ...quote, customer, vehicle })
        }
    }

    useEffect(() => {
        initialLoad()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <MuiDrawer
            open={open}
            handleOpen={() => setOpen(true)}
            radius={2}
            trigger={{
                element: 'IconButton',
                icon: action === 'create' ? 'Add' : 'Edit',
            }}
        >
            <FormBuilder
                initialValues={initialValues}
                formFields={formFields(vehicles)}
                validationSchema={validationSchema}
                title={title}
                handleSubmit={handleSubmit}
                submitButtonText={title}
            />
        </MuiDrawer>
    )
}

export default QuoteForm
