import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material'
import { MuiIcon } from '@instantia/mui-kit.ui'
import { chessGridContentRows } from './constants'

const isOdd = (num) => {
    return Boolean(num % 2 !== 0)
}

const ContentBox = ({ item }) => (
    <Box
        sx={{
            height: 375,
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
        }}
    >
        <Box sx={{ pl: 3 }}>
            <MuiIcon name={item.icon} sx={{ fontSize: 60, color: 'grey' }} />
        </Box>
        <Box p={3}>
            <Typography>{item.content}</Typography>
        </Box>
    </Box>
)

const ChessRow = () => (
    <Box>
        {chessGridContentRows.map((item, index) => {
            const bgColor = isOdd(index) ? '#dae1ea' : '#ffffff'
            return (
                <Box key={item.icon} sx={{ pt: 3, background: bgColor }}>
                    <Container>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                {isOdd(index) ? (
                                    <img alt={'feature-alt'} src={item.image} width={'100%'} />
                                ) : (
                                    <ContentBox item={item} />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {isOdd(index) ? (
                                    <ContentBox item={item} />
                                ) : (
                                    <img alt="feature-alt" src={item.image} width={'100%'} />
                                )}
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            )
        })}
    </Box>
)

export default ChessRow
