import React, { useState } from 'react'
import { FormBuilder, MuiDrawer } from '@instantia/mui-kit.ui'
import { formFields, validationSchema } from './constants'
import { Box, Divider, Paper, Typography } from '@mui/material'
import { Stack } from '@mui/system'

const Update = ({ handleUpdate, task, quote }) => {
    const [open, setOpen] = useState(false)
    const toggleOpen = () => {
        setOpen(!open)
    }

    const handleUpdateTask = async (data) => {
        const updated = await handleUpdate(data)
        if (updated) {
            toggleOpen()
        }
    }

    const quoteLine = quote.lines.filter(({ id }) => task.quoteLine === id)[0]

    return (
        <MuiDrawer
            radius={2}
            open={open}
            title="Update Task Tasks"
            handleOpen={toggleOpen}
            handleClose={toggleOpen}
            trigger={{
                element: 'IconButton',
                icon: 'MoreHoriz',
                size: 'small',
            }}
        >
            <Typography variant="h4">Update Task</Typography>
            <Paper sx={{ mt: 1, mb: 2 }} variant="outlined">
                <Stack spacing={1} sx={{ pb: 2 }}>
                    <Typography variant="subtitle">Task Quote Details</Typography>
                    <Divider />
                    <Typography variant="overline">Service {task.service.name}</Typography>
                    <Typography variant="overline">
                        Charged: £ {quoteLine.charged} (ex VAT)
                    </Typography>
                    <Typography variant={'overline'}>Description/Noted</Typography>
                    <Box
                        sx={{
                            p: 1,
                            borderRadius: 1,
                            backgroundColor: '#fafafa',
                        }}
                    >
                        <Typography>{quoteLine.description}</Typography>
                    </Box>
                </Stack>
            </Paper>
            <FormBuilder
                initialValues={{
                    id: task.id,
                    status: task.status,
                    description: task.description,
                }}
                title={''}
                validationSchema={validationSchema}
                formFields={formFields}
                handleSubmit={handleUpdateTask}
                submitButtonText={'Update Task'}
            />
        </MuiDrawer>
    )
}

export default Update
