import React, { useCallback, useEffect, useState } from 'react'
import { DashboardPage } from 'lib/components/layout/page'
import { useLocation, useParams } from 'react-router-dom'
import { generateBreadcrumbs } from 'lib/utilities'
import api from 'app/api'
import { toast } from 'react-toastify'
import { Grid } from '@mui/material'
import { CustomerDetails, CustomerStats } from './partial'
import { useDispatch, useSelector } from 'react-redux'
import { setCustomerEntity } from 'app/redux/customer/slice'
import CustomerVehicles from './vehicles'
import CustomerQuotes from './quotes'

const Read = () => {
    const [loading, setLoading] = useState(true)
    const customer = useSelector((state) => state.CUSTOMER)

    const dispatch = useDispatch()
    const location = useLocation()
    const { id } = useParams()
    const { pathname } = location

    const getCustomer = useCallback(() => {
        api({ entity: 'customers', action: 'read', data: id })
            .then(({ data }) => {
                dispatch(setCustomerEntity({ entity: 'details', data }))
            })
            .catch(() => {
                toast.error('Error retrieving customer')
            })
            .finally(() => {
                setLoading(false)
            })
    }, [id, dispatch])

    useEffect(() => {
        getCustomer()
        return () => dispatch(setCustomerEntity({ entity: 'details', data: null }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const breadcrumbs = generateBreadcrumbs({
        icon: 'WorkOutline',
        name: 'Customers',
        pathname,
        action: 'view',
        label: customer?.name || '',
    })

    if (loading) return 'Loading'

    return (
        <DashboardPage title="Customer" breadcrumbs={breadcrumbs}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={3}>
                    <CustomerStats />
                </Grid>

                <Grid item xs={12} sm={6} md={9}>
                    <CustomerDetails customer={customer.details} />
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={6}>
                            <CustomerVehicles />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <CustomerQuotes />
                        </Grid>
                    </Grid>
                </Grid>

                {/*<Grid item xs={12} sm={6}>*/}
                {/*    <CustomerJobs />*/}
                {/*</Grid>*/}

                {/*<Grid item xs={12} sm={6}>*/}
                {/*    <CustomerInvoices />*/}
                {/*</Grid>*/}
            </Grid>
        </DashboardPage>
    )
}

export default Read
