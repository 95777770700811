import auth from './authentication'
import tenants from './tenants'
import customers from './customers'
import vehicles from './vehicles'
import services from './services'
import expenseCategories from './expenseCategories'
import expenses from './expenses'
import invoices from './invoices'
import quotes from './quotes'
import suppliers from './suppliers'
import jobs from './jobs'
import queryStore from './queryStore'

const entities = {
    auth,
    tenants,
    customers,
    vehicles,
    services,
    expenseCategories,
    expenses,
    invoices,
    quotes,
    suppliers,
    jobs,
    queryStore,
}

const api = async ({ entity, action, data }) => {
    return await entities[entity][action](data)
}

export default api
