
import {Box, Typography} from "@mui/material";
import React from 'react';

const SubtleCard = ({title, description, footer}) => (
    <Box

        sx={{
            borderRadius: 1,
            backgroundColor: '#fafafa',
            p: 2,
            display: 'flex',
            flexDirection: 'column',
        }}
    >
        <Typography variant="overline">{title}</Typography>

        <Box
            sx={{
                width: '100%',
                wordWrap: 'break-word',
                overflow: 'hidden',
                maxHeight: 45,
                display: 'block',
                textOverflow: 'ellipsis',
                pt: 1,
                pb: 1
            }}
        >
            <Typography variant="body1">{description}</Typography>
        </Box>

        {footer &&
            <Box
                sx={{
                    pt: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
            {footer?.text &&
                <Typography>
                    <small>{footer.text}</small>
                </Typography>
            }
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                {footer.actions}
            </Box>
        </Box>
        }
    </Box>
);

export default SubtleCard;
