import React, { useEffect, useState } from 'react'
import { FormContainer } from 'lib/components/layout/form'
import { FormBuilder, MuiIcon } from '@instantia/mui-kit.ui'
import { formFields, getCategories, getSuppliers, validationSchema } from '../constants'
import RtlBox from 'lib/components/bits/RtlBox'
import { Button } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import api from 'app/api'
import { toast } from 'react-toastify'
import { DashboardPage } from 'lib/components/layout/page'
import { generateBreadcrumbs } from 'lib/utilities'

const Create = () => {
    const [suppliers, setSuppliers] = useState([])
    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const breadcrumbs = generateBreadcrumbs({
        icon: 'WorkOutline',
        name: 'Expenses',
        pathname,
        action: 'create',
    })
    const handleCreateExpense = (category) => {
        api({ entity: 'expenses', action: 'create', data: category })
            .then(({ status }) => {
                if (status === 200) {
                    toast.success('Expense category created successfully')
                    navigate(-1)
                }
            })
            .catch((err) => {
                console.error(err)
            })
    }

    useEffect(() => {
        ;(async () => {
            const _categories = await getCategories()
            setCategories(_categories)
            const _suppliers = await getSuppliers()
            setSuppliers(_suppliers)
        })().finally(() => setLoading(false))
    }, [])

    if (loading) return ''

    return (
        <DashboardPage title="New Expense" breadcrumbs={breadcrumbs}>
            <FormContainer>
                <RtlBox>
                    <Button
                        variant="outlined"
                        onClick={() => navigate(-1)}
                        startIcon={<MuiIcon name={'ArrowBack'} />}
                    >
                        Back
                    </Button>
                </RtlBox>
                <FormBuilder
                    title={'New Expense'}
                    formFields={formFields(suppliers, categories)}
                    initialValues={{
                        name: '',
                        description: '',
                    }}
                    validationSchema={validationSchema}
                    submitButtonText={'Create Expense'}
                    handleSubmit={handleCreateExpense}
                />
            </FormContainer>
        </DashboardPage>
    )
}

export default Create
