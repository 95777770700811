import React, { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import api from 'app/api'
import { toast } from 'react-toastify'
import List from './list'
import { useDispatch, useSelector } from 'react-redux'
import { customerSelector } from 'app/redux/selectors'
import { setCustomerEntity } from 'app/redux/customer/slice'

const Quotes = () => {
    const { id } = useParams()
    const entity = 'quotes'
    const dispatch = useDispatch()
    const { quotes } = useSelector(customerSelector)

    const getQuotes = useCallback(() => {
        api({ entity, action: 'list', data: { customer: id } })
            .then(({ data }) => dispatch(setCustomerEntity({ entity, data })))
            .catch(() => {
                toast.error('Unknown error retrieving quotes')
            })
    }, [id, dispatch])

    const handleDeleteQuote = (_quote) => {
        api({ entity: 'quotes', action: 'delete', data: _quote }).then(({ status }) => {
            if (status === 204) {
                const updated = quotes.filter((item) => _quote !== item.id)
                dispatch(setCustomerEntity({ entity, data: updated }))
                toast.success('Remove quote successfully')
            }
        })
    }

    useEffect(() => {
        getQuotes()
        return () => dispatch(setCustomerEntity({ entity, data: null }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return <List quotes={quotes} handleDeleteQuote={handleDeleteQuote} />
}

export default Quotes
