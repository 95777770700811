import React, { useEffect, useState } from 'react'
import { FormBuilder, MuiDrawer } from '@instantia/mui-kit.ui'
import { formFields, validationSchema } from './constants'
import api from 'app/api'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'

const EditLine = ({ setQuote, line }) => {
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const entity = 'quotes'
    const { id } = useParams()
    const [services, setServices] = useState(null)

    const handleUpdateQuoteLine = (line) => {
        api({
            entity,
            action: 'updateLine',
            data: {
                quote: id,
                ...line,
            },
        })
            .then(({ data }) => {
                setQuote(data)
                setOpen(false)
            })
            .catch((err) => {
                console.error(err)
                toast.error('Failed to update quote line')
            })
    }

    useEffect(() => {
        if (open && !loading && !services) {
            api({ entity: 'services', action: 'list' })
                .then(({ data }) => setServices(data))
                .catch((err) => console.error(err))
                .finally(() => setLoading(false))
        }
    }, [open, loading, services])

    if (loading) return ''

    return (
        <MuiDrawer
            open={open}
            handleOpen={() => setOpen(true)}
            radius={2}
            trigger={{ element: 'IconButton', icon: 'DriveFileRenameOutline', size: 'small' }}
            title={'Update Quote Line'}
        >
            <FormBuilder
                title={'Update Quote Line'}
                initialValues={{
                    ...line,
                    //An ugly way to handling null/undefined, but we move.
                    description: !line.description ? '' : line.description,
                    service: line?.service?.id,
                }}
                validationSchema={validationSchema}
                formFields={formFields(services)}
                submitButtonText={'Update Quote Line'}
                handleSubmit={handleUpdateQuoteLine}
            />
        </MuiDrawer>
    )
}

export default EditLine
