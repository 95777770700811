import React from 'react'
import { FormContainer } from 'lib/components/layout/form'
import { FormBuilder, MuiIcon } from '@instantia/mui-kit.ui'
import { formFields, validationSchema } from '../constants'
import RtlBox from 'lib/components/bits/RtlBox'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import api from 'app/api'
import { toast } from 'react-toastify'

const Create = () => {
    const navigate = useNavigate()
    const handleCreateExpenseCategory = (category) => {
        api({ entity: 'expenseCategories', action: 'create', data: category })
            .then(({ status }) => {
                if (status === 200) {
                    toast.success('Expense category created successfully')
                    navigate(-1)
                }
            })
            .catch((err) => {
                console.error(err)
            })
    }

    return (
        <FormContainer>
            <RtlBox>
                <Button
                    variant="outlined"
                    onClick={() => navigate(-1)}
                    startIcon={<MuiIcon name={'ArrowBack'} />}
                >
                    Back
                </Button>
            </RtlBox>
            <FormBuilder
                title={'New Expense Category'}
                formFields={formFields}
                initialValues={{
                    name: '',
                    description: '',
                }}
                validationSchema={validationSchema}
                submitButtonText={'Create Expense Category'}
                handleSubmit={handleCreateExpenseCategory}
            />
        </FormContainer>
    )
}

export default Create
