import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { getJob } from '../constants'
import { generateBreadcrumbs } from 'lib/utilities'
import { DashboardPage } from 'lib/components/layout/page'
import AddressBox from 'lib/components/bits/AddressBox'
import VehicleBox from 'lib/components/bits/VehicleBox'
import Comments from 'lib/components/comments'
import { Masonry } from '@mui/lab'
import api from 'app/api'
import { toast } from 'react-toastify'
import Tasks from './tasks'
import JobStatus from './partials/JobStatus'
import InvoiceQuoteCard from 'lib/components/bits/InvoiceQuoteCard'

const Read = () => {
    const [job, setJob] = useState(null)
    const { pathname } = useLocation()
    const { id } = useParams()

    useEffect(() => {
        getJob(id).then((data) => setJob(data))
    }, [id])

    const handleCreateComment = async (comment) => {
        const { data } = await api({
            entity: 'jobs',
            action: 'addComment',
            data: { ...comment, jobId: id },
        })

        if (data) {
            setJob(data)
            toast.success('Comment added successfully.')
            return true
        }

        toast.error('Error creating comment.')
        return false
    }

    const handleUpdateComment = async (comment) => {
        const { data } = await api({
            entity: 'jobs',
            action: 'updateComment',
            data: { ...comment, jobId: id },
        })

        if (data) {
            setJob(data)
            toast.success('Comment updated successfully.')
            return true
        }
        toast.error('Error updating comment.')
        return false
    }

    const handleUpdateTask = async (task) => {
        const { data } = await api({
            entity: 'jobs',
            action: 'updateTask',
            data: { ...task, jobId: id },
        }).catch((err) => console.error('failed to update task', err))

        if (data) {
            setJob(data)
            toast.success('Job task updated successfully.')
            return true
        }

        toast.error('Error updating task')
        return false
    }

    const handleDeleteComment = async (comment) => {
        const { data } = await api({
            entity: 'jobs',
            action: 'deleteComment',
            data: { id: comment, jobId: id },
        })

        if (data) {
            setJob(data)
            toast.success('Comment deleted successfully.')
            return true
        }
        toast.error('Error deleting comment.')
        return false
    }

    const handleUpdateJobStatus = async (status) => {
        const { data } = await api({
            entity: 'jobs',
            action: 'update',
            data: { id, status },
        })

        if (data) {
            setJob(data)
            toast.success('Job status updated')
            return true
        }
    }

    const breadcrumbs = generateBreadcrumbs({
        icon: 'WorkOutline',
        name: 'Customers',
        pathname,
        action: 'view',
        label: 'Job',
    })

    if (!job) return ''

    return (
        <DashboardPage title="Job" breadcrumbs={breadcrumbs}>
            <Masonry columns={{ xs: 1, sm: 2, md: 3 }} spacing={1}>
                <AddressBox name={job.customer.name} address={job.customer.address} />

                <VehicleBox {...job.vehicle} />

                <InvoiceQuoteCard title={'Quote Details'} {...job.quote} />

                {job.invoice && <InvoiceQuoteCard title={'Invoice Details'} {...job.invoice} />}

                <Tasks quote={job.quote} tasks={job.tasks} handleUpdate={handleUpdateTask} />

                <Comments
                    title="Job Comments"
                    comments={job.comments}
                    handleCreate={handleCreateComment}
                    handleUpdate={handleUpdateComment}
                    handleDelete={handleDeleteComment}
                />

                <JobStatus job={job} handleUpdate={handleUpdateJobStatus} />
            </Masonry>
        </DashboardPage>
    )
}

export default Read
