import React from 'react'
import { categories } from '../constants'
import { SimpleKpi } from '@instantia/mui-kit.ui'
import { Stack } from '@mui/system'

const CustomerStats = () => {
    return (
        <Stack spacing={1}>
            {categories.map((item, index) => {
                return <SimpleKpi title={item.title} metric={item.metric} key={index} />
            })}
        </Stack>
    )
}

export default CustomerStats
