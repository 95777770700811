import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Tenants from './Tenants'
import Create from './create'

const Screens = () => (
    <Routes>
        <Route key="list-tenants" path="/" element={<Tenants />} />
        <Route key="create-tenant" path="/create" element={<Create />} />
    </Routes>
)

export default Screens
