import axios from 'axios'

const prefix = `api/tenants`

const tenants = {
    list: (params) => axios.get(`${prefix}/`, { params }),
    create: async (tenant) => axios.post(`${prefix}/`, tenant),
    read: async (tenant) => axios.get(`${prefix}/${tenant}`),
    query: async (data) => axios.post(`${prefix}/query`, data),
    update: async (tenant) => axios.patch(`${prefix}/${tenant.id}`, tenant),
    delete: async (tenant) => axios.delete(`${prefix}/${tenant}`),
}

export default tenants
