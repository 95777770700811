import * as Yup from 'yup'
import api from 'app/api'
import { toast } from 'react-toastify'

export const initialValues = {
    name: '',
    email: '',
    phone: '',
    paymentTerms: '3 Days',
    accountType: 'Private',
}

export const formFields = [
    {
        component: 'MuiTextField',
        name: 'name',
        label: 'name',
    },
    {
        component: 'MuiTextField',
        type: 'email',
        name: 'email',
        label: 'Email',
        size: {
            xs: 12,
            sm: 6,
            md: 6,
        },
    },
    {
        component: 'MuiTextField',
        name: 'phone',
        label: 'Phone',
        size: {
            xs: 12,
            sm: 6,
            md: 6,
        },
    },
    {
        component: 'MuiSelectField',
        name: 'paymentTerms',
        label: 'Payment Terms',
        options: [
            { label: '3 Days', value: '3 Days' },
            { label: '7 Days', value: '7 Days' },
            { label: '14 Days', value: '14 Days' },
            { label: '28 Days', value: '28 Days' },
        ],
        size: {
            xs: 12,
            sm: 6,
            md: 6,
        },
    },
    {
        component: 'MuiSelectField',
        name: 'account',
        label: 'Account Type',
        options: [
            { label: 'Private', value: 'Private' },
            { label: 'Trade', value: 'Trade' },
        ],
        size: {
            xs: 12,
            sm: 6,
            md: 6,
        },
    },
]

export const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid Email').required('Email is Required'),
    name: Yup.string().min(4).required('Full Name is required'),
    phone: Yup.string().min(11).max(15),
})

export const getCustomer = async (id, callback) => {
    const { data } = await api({ entity: 'customers', action: 'read', data: id }).catch(() => {
        toast.error('Error retrieving customer')
    })

    callback(data)
}
