import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
    name: 'AUTH',
    initialState: {
        isAuthenticated: false,
        loading: false,
        user: null,
        profile: null,
        errors: null,
    },
    reducers: {
        setAuthUser: (state, { payload }) => {
            state.isAuthenticated = Boolean(payload)
            state.user = payload
            state.loading = false
        },
        setAuthUserProfile: (state, { payload }) => {
            state.profile = payload
            state.loading = false
        },
        setAuthLoading: (state, { payload }) => {
            state.loading = payload
        },
        setAuthErrors: (state, { payload }) => {
            state.errors = payload
            state.loading = false
        },
    },
})

export const { setAuthUser, setAuthUserProfile, setAuthLoading, setAuthErrors } = authSlice.actions

export default authSlice.reducer
