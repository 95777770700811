import { Box, List, ListItem, Paper, Typography } from '@mui/material'
import React from 'react'
import useStyles from 'theme/styles'
import PropTypes from 'prop-types'

const InvoiceQuoteCard = ({ title, notes, subTotal, vatTotal, total }) => {
    const { rowSpaceBetween } = useStyles()
    return (
        <Paper variant="outlined" sx={{ p: 2 }}>
            <List>
                <ListItem>
                    <Typography variant="overline">{title}</Typography>
                </ListItem>
                <ListItem>
                    <Box sx={{ pb: 1, width: '100%' }}>
                        {notes && (
                            <Box sx={{ pb: 1 }}>
                                <Typography variant={'body1'}>
                                    <b>Notes</b>
                                </Typography>
                                <Typography variant={'body2'}>{notes}</Typography>
                            </Box>
                        )}
                        <Box className={rowSpaceBetween}>
                            <Typography variant={'body1'}>Sub Total</Typography>
                            <Typography variant={'body1'}>£ {subTotal?.toFixed(2)}</Typography>
                        </Box>
                        <Box className={rowSpaceBetween}>
                            <Typography variant={'body1'}>Vat</Typography>
                            <Typography variant={'body1'}>£ {vatTotal?.toFixed(2)}</Typography>
                        </Box>
                        <Box className={rowSpaceBetween}>
                            <Typography variant={'body1'}>Total</Typography>
                            <Typography variant={'body1'}>£ {total?.toFixed(2)}</Typography>
                        </Box>
                    </Box>
                </ListItem>
            </List>
        </Paper>
    )
}

InvoiceQuoteCard.propTypes = {
    title: PropTypes.string.isRequired,
    notes: PropTypes.string,
    subTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    vatTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

InvoiceQuoteCard.defaultProps = {
    notes: null,
    subTotal: 0,
    vatTotal: 0,
    total: 0,
}

export default InvoiceQuoteCard
