import React, { useEffect, useState } from 'react'
import { DashboardPage } from 'lib/components/layout/page'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { generateBreadcrumbs } from 'lib/utilities'
import { formFields, getJob, validationSchema } from '../constants'
import { FormContainer } from 'lib/components/layout/form'
import { FormBuilder } from '@instantia/mui-kit.ui'
import api from 'app/api'
import { toast } from 'react-toastify'

const Update = () => {
    const [job, setJob] = useState(null)
    const navigate = useNavigate()
    const { id } = useParams()
    const { pathname } = useLocation()
    const breadcrumbs = generateBreadcrumbs({
        icon: 'HomeRepairService',
        name: 'Jobs',
        pathname,
        action: 'update',
    })

    const handleUpdateJob = async (data) => {
        const res = await api({ entity: 'services', action: 'update', data }).catch((err) => {
            toast.error('Error updating job.')
            console.error(err)
        })
        if (res.status === 200) {
            navigate(-1)
            setJob(res.data)
            toast.success('Job updated successfully')
        }
    }

    useEffect(() => {
        getJob(id).then((data) => setJob(data))
    }, [id])

    if (!job) return ''

    return (
        <DashboardPage title="Update Job" breadcrumbs={breadcrumbs}>
            <FormContainer>
                <FormBuilder
                    title="Update job"
                    initialValues={job}
                    validationSchema={validationSchema}
                    submitButtonText="Update Job"
                    formFields={formFields}
                    handleSubmit={handleUpdateJob}
                />
            </FormContainer>
        </DashboardPage>
    )
}

export default Update
