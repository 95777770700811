export const tableColumns = (Actions) => {
    return [
        {
            field: 'vehicle',
            headerName: 'Vehicle',
            valueGetter: ({ row }) => row.vehicle.registration || '',
        },
        {
            field: 'notes',
            headerName: 'Quote Notes',
            width: 150,
        },
        {
            field: 'lines',
            headerName: '# Items',
            valueGetter: ({ row }) => row.lines.length,
        },
        {
            field: 'total',
            headerName: 'Total',
            valueGetter: ({ row }) => `£ ${row.total || 0}`,
        },
        {
            field: 'id',
            headerName: '',
            width: 135,
            renderCell: ({ row }) => <Actions row={row} />,
        },
    ]
}
