import React from 'react'
import { Box, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'

const TableContainer = ({ minHeight, hideFooter, title, action, rows, ...rest }) => {
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 10,
        page: 0,
    })

    const height = minHeight || '85vh'

    return (
        <Box>
            {(title || action) && (
                <Box
                    sx={{
                        pb: 1,
                        height: 45,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography variant="subtitle1">{title}</Typography>
                    <Box>{action}</Box>
                </Box>
            )}

            <Box sx={{ height }}>
                <DataGrid
                    autoPageSize
                    rowHeight={40}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    rowsPerPageOptions={[10, 20]}
                    experimentalFeatures={{ newEditingApi: true }}
                    disableSelectionOnClick
                    hideFooter={hideFooter}
                    rows={rows || []}
                    {...rest}
                />
            </Box>
        </Box>
    )
}

export default TableContainer
