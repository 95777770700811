import * as Yup from 'yup'

export const breadcrumbs = [
    {
        tenants: [
            {
                key: 'tenants',
                name: 'Tenants',
                icon: 'Apps',
                link: null,
            },
        ],
    },
    {
        create: [
            {
                key: 'tenants',
                name: 'Tenants',
                icon: 'Apps',
                link: '/tenants',
            },
            {
                key: 'create',
                name: 'New Tenant',
                icon: null,
                link: null,
            },
        ],
    },
    {
        edit: [
            {
                key: 'tenants',
                name: 'Tenants',
                icon: 'Apps',
                link: '/tenants',
            },
            {
                key: 'edit',
                name: 'Edit Tenant',
                icon: null,
                link: null,
            },
        ],
    },
    {
        edit: [
            {
                key: 'tenants',
                name: 'Tenants',
                icon: 'Apps',
                link: '/tenants',
            },
            {
                key: 'view',
                name: 'View Tenant',
                icon: null,
                link: null,
            },
        ],
    },
]

export const initialValues = {
    name: '',
    domain: '',
}
export const formFields = [
    {
        component: 'MuiTextField',
        type: 'text',
        name: 'name',
        label: 'Company Name',
    },
    {
        component: 'MuiTextField',
        type: 'text',
        name: 'domain',
        label: 'Domain',
    },
]

export const validationSchema = Yup.object().shape({
    name: Yup.string().min(4, 'Company name must be at least 4 characters').required(),
    domain: Yup.string()
        .min(4, 'Domain must be at least 4 characters.')
        .trim()
        .matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, 'Must be alphanumeric characters')
        .required(),
})
