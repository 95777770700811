import React from 'react'
import { Box, Container, Grid, Paper, Typography } from '@mui/material'
import { moreFeatures } from './constants'
import { MuiIcon } from '@instantia/mui-kit.ui'
import useStyles from 'theme/styles'

const MoreFeatures = () => {
    const { centerContent } = useStyles()

    return (
        <Box sx={{ mt: 2, mb: 3 }}>
            <Container>
                <Box sx={{ pb: 2 }} className={centerContent}>
                    <Typography variant="h3" color="grey">
                        FEATURES
                    </Typography>
                </Box>

                <Grid container spacing={2}>
                    {moreFeatures.map((feature) => (
                        <Grid key={feature.icon} item xs={12} sm={4}>
                            <Paper sx={{ minHeight: 300 }}>
                                <Box sx={{ p: 1 }}>
                                    <MuiIcon
                                        name={feature.icon}
                                        sx={{ fontSize: 60, color: 'grey' }}
                                    />
                                </Box>

                                <Box p={1}>
                                    <Typography variant="overline">{feature.title}</Typography>
                                </Box>

                                <Box p={1}>
                                    <Typography variant="body2">{feature.content}</Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    )
}

export default MoreFeatures
