import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material'
import { AppLogo } from '@instantia/mui-kit.ui'
import Logo from 'assets/images/logo/maneja.png'

const Footer = () => (
    <Box
        sx={{
            height: 100,
            borderTop: '1px solid #ccc',
            display: 'flex',
            alignItems: 'center',
        }}
    >
        <Container>
            <Grid container>
                <Grid item xs={12} sm={4}>
                    <Box
                        sx={{
                            width: 100,
                        }}
                    >
                        <AppLogo img={{ src: Logo }} />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4}></Grid>
                <Grid item xs={12} sm={4}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Typography>© Maneja 2023</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    </Box>
)

export default Footer
