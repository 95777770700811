import React from 'react'
import { Box } from '@mui/material'

const Loading = () => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        PLEASE WAIT
    </Box>
)

export default Loading
