import dayjs from 'dayjs'
import TableActionButtons from 'lib/components/bits/TableActionButtons'
import * as Yup from 'yup'
import 'yup-phone'
import api from '../../api'
import { toast } from 'react-toastify'

export const categories = [
    {
        title: 'Expense Invoices',
        metric: '40',
    },
    {
        title: 'Expense Total',
        metric: '£ 340.98',
    },
]

export const tableColumns = (actions) => {
    return [
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 150,
        },
        {
            field: 'phone',
            headerName: 'Phone',
            width: 150,
        },
        {
            field: 'address',
            headerName: 'Location',
            width: 150,
            valueGetter: ({ row }) =>
                `${row?.address?.city || ''}  ${row.address?.portalCode || ''}`,
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 300,
        },
        {
            field: 'created_at',
            headerName: 'Created Date',
            width: 150,
            valueGetter: ({ row }) => `${dayjs(row.created_at).fromNow()}`,
        },
        {
            field: 'id',
            headerName: 'Actions',
            width: 200,
            renderCell: ({ row }) => (
                <TableActionButtons title={'supplier'} actions={actions(row.id)} />
            ),
        },
    ]
}

export const formFields = [
    {
        component: 'MuiTextField',
        name: 'name',
        label: 'Name',
    },
    {
        component: 'MuiTextField',
        name: 'email',
        label: 'Email',
    },
    {
        component: 'MuiTextField',
        name: 'phone',
        label: 'Phone',
    },
    {
        component: 'MuiTextField',
        name: 'description',
        label: 'Description',
        multiline: true,
        rows: 5,
    },
    {
        component: 'MuiCheckboxField',
        name: 'chargeVat',
        label: 'Add VAT to purchase invoices (20%)',
    },
]

export const validationSchema = Yup.object().shape({
    name: Yup.string().required('Supplier Name is required'),
    email: Yup.string().email(),
    phone: Yup.string(),
    description: Yup.string().required('Supplier description is required').min(32),
})

export const getSupplier = async (id) => {
    const res = await api({ entity: 'suppliers', action: 'read', data: id }).catch((err) => {
        console.error(err)
        toast.error('Error retrieving supplier')
    })

    if (res.status === 200) {
        return res.data
    }
}
