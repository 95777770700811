export const categories = [
    {
        title: 'Invoices',
        metric: '3',
    },
    {
        title: 'Total',
        metric: '125.33',
    },
    {
        title: 'Un Paid Invoices',
        metric: '3',
    },
    {
        title: 'Un Paid Total',
        metric: '125.33',
    },
]
