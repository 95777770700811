import React, { useEffect, useState } from 'react'
import { Stack } from '@mui/system'
import { Box, Button, CardActions, List, ListItem, Paper, Typography } from '@mui/material'
import ProgressBar from './ProgressBar'
import Update from './Update'
import { useDialog } from 'lib/context/Dialog'
import { useNavigate } from 'react-router-dom'
import useStyles from 'theme/styles'

const Tasks = ({ tasks, quote, handleUpdate }) => {
    const [count, setCount] = useState(0)
    const [completedCount, setCompletedCount] = useState(0)
    const dialog = useDialog()
    const navigate = useNavigate()
    const styles = useStyles()

    const getCompletedCount = () => {
        return tasks.filter(({ status }) => status === 'completed' || status === 'cancelled').length
    }

    const calculateIncomplete = () => {
        return 100 - ((completedCount / count) * 100).toFixed(0)
    }

    const handleAddTask = () => {
        dialog.setData({
            title: 'Add Job Task',
            content:
                'Job tasks can only be added by amending the initial job quote. New quote lines will be added to this job as tasks. Confirm to update quote',
            handleConfirm: () => navigate(`/quotes/${quote.id}/read`),
        })
        dialog.setOpen(true)
    }

    useEffect(() => {
        if (tasks) {
            setCount(tasks.length)
            setCompletedCount(getCompletedCount())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tasks])

    return (
        <Paper variant="outlined">
            <List>
                <ListItem>
                    <Typography variant="overline"> Tasks Completed </Typography>
                </ListItem>

                <ListItem>
                    <Stack spacing={1} sx={{ width: '100%' }}>
                        <Box
                            sx={{
                                p: 2,
                                borderRadius: 1,
                                backgroundColor: '#fafafa',
                            }}
                        >
                            <Box className={styles.rowSpaceBetween}>
                                <div>
                                    <Typography>Completed</Typography>
                                    <Typography>
                                        <small>{completedCount}</small> (
                                        {((completedCount / count) * 100).toFixed(0)} %)
                                    </Typography>
                                </div>
                                <div>
                                    <Typography>In Complete</Typography>
                                    <Typography>
                                        <small>{count}</small> ({calculateIncomplete()} %)
                                    </Typography>
                                </div>
                            </Box>
                        </Box>

                        {tasks.map((item) => (
                            <Box
                                key={item.id}
                                sx={{
                                    borderRadius: 1,
                                    backgroundColor: '#fafafa',
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Box className={styles.rowSpaceBetween}>
                                    <span> {item.service.name} </span>
                                    <span> {item.status} </span>
                                </Box>

                                <Box className={styles.rowSpaceBetween}>
                                    <ProgressBar status={item.status} />
                                    <Update task={item} handleUpdate={handleUpdate} quote={quote} />
                                </Box>
                            </Box>
                        ))}
                    </Stack>
                </ListItem>
            </List>
            <CardActions>
                <Button variant={'text'} size="small" onClick={handleAddTask}>
                    Add Task
                </Button>
            </CardActions>
        </Paper>
    )
}

export default Tasks
