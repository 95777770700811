import React from 'react'
import logo from 'assets/images/logo/maneja.png'
import { DashboardPage, MuiBreadcrumbs } from '@instantia/mui-kit.ui'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import { pages } from 'constants'
import { Box } from '@mui/material'
import useStyles from 'theme/styles'

const Dashboard = ({ children, title, breadcrumbs }) => {
    const styles = useStyles()
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const [, current] = pathname.split('/')

    const items = pages.map((page) => {
        return { ...page, onClick: () => navigate(page.path) }
    })

    return (
        <DashboardPage
            appTopBar={{
                title: {
                    img: { src: logo },
                    height: 60,
                },
                settings: [
                    { icon: 'Logout', label: 'Logout' },
                    { icon: 'Settings', label: 'Account' },
                ],
                appBarProps: {
                    sx: {
                        bgcolor: 'transparent',
                        margin: '12px',
                        width: `auto !important`,
                    },
                },
            }}
            appSideBar={{
                paperProps: {
                    variant: 'outlined',
                    elevation: 0,
                    sx: {
                        borderRadius: 0,
                        backgroundColor: 'transparent',
                        border: 'none',
                    },
                },
                menu: {
                    current: `/${current}`,
                    items: items,
                    selectedItemClass: styles.styledSelectedMenuItem,
                },
            }}
        >
            {breadcrumbs && (
                <Box sx={{ pt: 2 }}>
                    <MuiBreadcrumbs items={breadcrumbs} />
                </Box>
            )}
            <Box sx={{ p: 2 }}>{children}</Box>
        </DashboardPage>
    )
}

Dashboard.propTypes = {
    title: PropTypes.string,
    breadcrumbs: PropTypes.arrayOf(PropTypes.shape({})),
}

Dashboard.defaultProps = {
    title: '',
    breadcrumbs: null,
}

export default Dashboard
