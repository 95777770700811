import React from 'react'
import { Box } from '@mui/material'

const RtlBox = ({ children }) => (
    <Box
        sx={{
            width: '100%',
            minHeight: 40,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            mb: 1,
        }}
    >
        {children}
    </Box>
)

export default RtlBox
