import React, { useState } from 'react'
import { Box, Chip, Container, Grid, Switch, Typography } from '@mui/material'
import PricingItem from './PricingItem'
import { monthlyPricingItems } from './constants'
import useStyles from 'theme/styles'

const Pricing = () => {
    const [yearlyChecked, setYearlyChecked] = useState(true)
    const handleMonthlySwitch = (e) => {
        setYearlyChecked(e.target.checked)
    }
    const { centerContent, centerContentInline } = useStyles()
    return (
        <Box sx={{ pb: 3, background: '#dae1ea' }}>
            <Container>
                <Box className={centerContent} p={2}>
                    <Typography variant="h3" color="grey">
                        PRICING Plans
                    </Typography>
                    <Typography variant="overline">
                        Our pricing plans are designed to be affordable, flexible and tailored to
                        your unique needs.
                    </Typography>
                </Box>

                <Box className={centerContentInline} p={2}>
                    <Typography>Monthly</Typography>
                    <Switch
                        checked={yearlyChecked}
                        onChange={handleMonthlySwitch}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography>Yearly</Typography>{' '}
                    <Chip label="Save 20%" size="small" variant="outlined" sx={{ ml: 1 }} />
                </Box>

                <Grid container spacing={2}>
                    {monthlyPricingItems.map((item) => (
                        <Grid key={item.title} item xs={12} sm={4}>
                            <PricingItem {...item} interval={yearlyChecked ? 'year' : 'month'} />
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    )
}

export default Pricing
