import React, { useEffect } from 'react'
import { Box, Grid, Paper } from '@mui/material'
import garage from 'assets/images/isometric/garage.png'
import logo from 'assets/images/logo/maneja.png'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { hasSubDomain } from '../../../utilities'

const background = {
    display: { xs: 'none', sm: 'block' },
    backgroundImage: `url(${garage})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: '100vh',
    width: '100%',
}

const Layout = ({ children }) => {
    const { isAuthenticated } = useSelector((state) => state.AUTH)
    const navigate = useNavigate()

    useEffect(() => {
        const landing = hasSubDomain() ? '/' : '/tenants'
        if (isAuthenticated) {
            navigate(landing)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, navigate])
    return (
        <Box>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={8}>
                    <Box sx={background} />
                </Grid>
                <Grid item sm={6} md={4}>
                    <Box
                        sx={{
                            height: '100vh',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignContent: 'center',
                        }}
                    >
                        <Paper
                            sx={{
                                m: 2,
                                p: 3,
                                minHeight: 250,
                                maxWidth: 350,
                            }}
                        >
                            <img src={logo} alt={'Brand Logo'} height={74} />
                            <Box p={2}>{children}</Box>
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Layout
