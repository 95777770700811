import dayjs from 'dayjs'
import TableActionButtons from 'lib/components/bits/TableActionButtons'
import React from 'react'

export const categories = [
    {
        measures: { metric: { $sum: 1 } },
        entity: 'customers',
        title: 'Customers',
    },
    {
        filters: { active: true },
        measures: { metric: { $sum: 1 } },
        entity: 'customers',
        title: 'Active Customers',
    },
]

export const tableColumns = (actions) => {
    return [
        {
            field: 'name',
            headerName: 'Full Name',
            width: 120,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 200,
        },
        {
            field: 'created_at',
            headerName: 'Created Date',
            width: 200,
            valueGetter: ({ row }) => `${dayjs(row.created_at).fromNow()}`,
        },
        {
            field: 'id',
            headerName: '',
            width: 120,
            renderCell: ({ row }) => (
                <TableActionButtons title={'customer'} actions={actions(row.id)} />
            ),
        },
    ]
}
