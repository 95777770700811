import dayjs from 'dayjs'
import TableActionButtons from 'lib/components/bits/TableActionButtons'
import * as Yup from 'yup'
import 'yup-phone'
import api from 'app/api'
import { toast } from 'react-toastify'

export const categories = [
    {
        measures: { metric: { $sum: 1 } },
        title: 'All',
        entity: 'jobs',
    },
    {
        filters: { status: { $in: ['not started', 'started', 'partially completed'] } },
        measures: { metric: { $sum: 1 } },
        entity: 'jobs',
        title: 'Active',
    },
    {
        filters: { status: { $in: ['completed'] } },
        measures: { metric: { $sum: 1 } },
        entity: 'jobs',
        title: 'Complete',
    },
    {
        filters: { status: { $in: ['cancelled'] } },
        measures: { metric: { $sum: 1 } },
        entity: 'jobs',
        title: 'Cancelled',
    },
]

export const tableColumns = (actions) => {
    return [
        {
            field: 'customer',
            headerName: 'Customer',
            valueGetter: ({ row }) => row?.customer?.name || '',
            width: 150,
        },
        {
            field: 'Vehicle',
            headerName: 'vehicle',
            valueGetter: ({ row }) =>
                `${row.vehicle.registration} [${row.vehicle.make} ${row.vehicle.model}]`,
            width: 250,
        },
        {
            field: 'quote',
            headerName: 'Priced Total',
            valueGetter: ({ row }) => `£ ${row?.quote?.total || '0.00'}`,
            width: 100,
        },
        {
            field: 'created_at',
            headerName: 'Created Date',
            width: 120,
            valueGetter: ({ row }) => `${dayjs(row.created_at).fromNow()}`,
        },
        {
            field: 'id',
            headerName: 'Actions',
            width: 120,
            renderCell: ({ row }) => <TableActionButtons title={'Job'} actions={actions(row.id)} />,
        },
    ]
}

export const formFields = [
    {
        component: 'MuiTextField',
        name: 'name',
        label: 'Name',
    },
    {
        component: 'MuiTextField',
        name: 'description',
        label: 'Description',
        multiline: true,
        rows: 5,
    },
    {
        component: 'MuiTextField',
        name: 'unitPrice',
        label: 'Unit Price (£)',
    },
    {
        component: 'MuiSelectField',
        name: 'unit',
        label: 'Unit measure',
        options: [
            { label: 'Single', value: 'item' },
            { label: 'Hourly', value: 'hourly' },
            { label: 'Flat Rate', value: 'service' },
        ],
    },
    {
        component: 'MuiCheckboxField',
        name: 'chargeVat',
        label: 'Add VAT (20%)',
    },
]

export const validationSchema = Yup.object().shape({
    name: Yup.string().required('Job Name is required'),
    description: Yup.string().required('Job Description is required').min(32),
    unitPrice: Yup.number(),
    unit: Yup.string(),
})

export const getJob = async (id) => {
    const res = await api({ entity: 'jobs', action: 'read', data: id }).catch((err) => {
        console.error(err)
        toast.error('Error retrieving job')
    })

    if (res.status === 200) {
        return res.data
    }
}
