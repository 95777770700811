import React from 'react'
import { Box, Container, Paper, Typography } from '@mui/material'
import useStyles from 'theme/styles'
import Gradient from 'rgt'
import RegisterInterest from './RegisterInterest'

const Jumbotron = () => {
    const { centerContent } = useStyles()
    return (
        <Container>
            <Box className={centerContent} sx={{ height: 345 }}>
                <Typography variant="h1" sx={{ fontSize: '2.3em', fontWeight: 900 }}>
                    The smartest way to
                </Typography>
                <Typography variant="h1" sx={{ fontSize: '2.1em', fontWeight: 900 }}>
                    <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                        manage your garage
                    </Gradient>
                </Typography>
                <Box sx={{ p: 1 }}>
                    <Typography variant="overline" sx={{ fontSize: '0.9em', fontWeight: 100 }}>
                        The Ultimate Data-Driven Garage Management Solution
                    </Typography>
                </Box>
            </Box>
            <Paper sx={{ p: 3 }}>
                <RegisterInterest />
            </Paper>
        </Container>
    )
}

export default Jumbotron
