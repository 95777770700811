import * as Yup from 'yup'

export const initialValues = {
    name: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
}

export const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().required(),
    phone: Yup.string().required(),
    password: Yup.string().required(),
    passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required(),
})

export const formFields = [
    {
        component: 'MuiTextField',
        name: 'name',
        label: 'Full Name',
        placeholder: '',
    },
    {
        component: 'MuiTextField',
        name: 'email',
        label: 'Email Address',
        placeholder: '',
    },
    {
        component: 'MuiTextField',
        name: 'phone',
        label: 'Mobile Phone',
        placeholder: '',
    },
    {
        component: 'MuiTextField',
        name: 'password',
        label: 'Password',
        type: 'password',
        placeholder: '',
    },
    {
        component: 'MuiTextField',
        name: 'passwordConfirmation',
        label: 'Confirm Password',
        type: 'password',
        placeholder: '',
    },
]
