import React from 'react'
import { FormBuilder, MuiDrawer } from '@instantia/mui-kit.ui'
import { formFields, validationSchema } from '../constants'

const Update = ({ handleUpdateVehicle, vehicle }) => {
    return (
        <MuiDrawer
            radius={2}
            trigger={{
                element: 'IconButton',
                icon: 'Edit',
            }}
        >
            <FormBuilder
                initialValues={vehicle}
                formFields={formFields}
                handleSubmit={handleUpdateVehicle}
                validationSchema={validationSchema}
                submitButtonText="Update Customer Vehicle"
                title="Update Vehicle"
            />
        </MuiDrawer>
    )
}

export default Update
