import React from 'react'
import { tableColumns } from './constants'
import TableContainer from 'lib/components/bits/TableContainer'
import { CreateQuoteLine } from './index'
import EditLine from './EditLine'
import { useDialog } from 'lib/context/Dialog'
import api from 'app/api'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import TableActionButtons from 'lib/components/bits/TableActionButtons'

const List = ({ lines, services, setQuote, quote }) => {
    const dialog = useDialog()
    const entity = 'quotes'
    const { id } = useParams()
    const handleDeleteQuoteLine = (_id) => {
        api({
            entity,
            action: 'deleteLine',
            data: {
                quote: id,
                _id,
            },
        })
            .then(({ data }) => {
                setQuote(data)
            })
            .catch((err) => {
                console.error(err)
                toast.error('Failed to delete quote line')
            })
    }

    const actions = (line) => {
        return {
            update: {
                Element: (
                    <EditLine key={'update'} line={line} services={services} setQuote={setQuote} />
                ),
            },
            delete: () => {
                dialog.setData({
                    title: 'Confirm Delete',
                    content: 'Are you sure you would like to delete this invoice line',
                    handleConfirm: () => handleDeleteQuoteLine(line._id),
                })
                dialog.setOpen(true)
            },
        }
    }

    const CreateQuoteLineAction = () => {
        if (quote.status === 'pending') {
            return <CreateQuoteLine services={services} setQuote={setQuote} />
        }
        return ''
    }

    const renderLineItemActions = ({ row }) => {
        if (quote.status === 'pending') {
            return <TableActionButtons actions={actions(row)} title={'Quote line'} />
        }
        return ''
    }

    return (
        <TableContainer
            title="Quote Lines"
            action={<CreateQuoteLineAction />}
            disableSelectionOnClick
            hideFooter
            minHeight={250}
            columns={[
                ...tableColumns,
                {
                    field: 'id',
                    headerName: '',
                    renderCell: renderLineItemActions,
                },
            ]}
            rows={lines}
        />
    )
}

export default List
