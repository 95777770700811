import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Provider as ReduxProvider } from 'react-redux'
import { ThemeProvider } from '@mui/material'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { theme } from 'theme'
import NavRoutes from 'app/navigation/NavRoutes'
import store from 'app/redux/store'
import './App.css'
import { DialogProvider } from './lib/context/Dialog'

const App = () => {
    return (
        <HelmetProvider>
            <Helmet>
                <title>Maneja</title>
                <link rel="cannonical" href="http://maneja.com" />
            </Helmet>

            <ThemeProvider theme={theme}>
                <ReduxProvider store={store}>
                    <DialogProvider>
                        <NavRoutes />
                    </DialogProvider>
                    <ToastContainer
                        position="bottom-center"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover={false}
                    />
                </ReduxProvider>
            </ThemeProvider>
        </HelmetProvider>
    )
}

export default App
