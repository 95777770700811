import React from 'react'
import { hasSubDomain, decodeAuthToken, setAuthToken } from 'lib/utilities'
import { FormBuilder } from '@instantia/mui-kit.ui'
import { formFields, validationSchema } from './constants'
import { Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Layout from 'lib/components/layout/authentication/Layout'
import api from 'app/api'
import { useDispatch } from 'react-redux'
import { setAuthUser } from 'app/redux/authentication/slice'

const SignIn = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleSubmit = async (data) => {
        const res = await api({
            entity: 'auth',
            action: 'signIn',
            data,
        }).catch((err) => {
            console.error(err)
        })

        const authToken = res.data.token
        localStorage.setItem('authToken', authToken)

        const user = decodeAuthToken(authToken)
        setAuthToken(authToken)
        dispatch(setAuthUser(user))
    }

    return (
        <Layout>
            <FormBuilder
                initialValues={{
                    email: '',
                    password: '',
                }}
                handleSubmit={handleSubmit}
                validationSchema={validationSchema}
                formFields={formFields}
                title="Sign In"
                submitButtonText="Sign In"
                submitFullWidth
            />

            {!hasSubDomain() && (
                <>
                    <Typography color="primary" sx={{ pb: 1 }}>
                        New to Maneja? Sign up to get started.
                    </Typography>

                    <Button
                        color="primary"
                        variant="outlined"
                        fullWidth
                        onClick={() => {
                            navigate('/sign-up')
                        }}
                    >
                        Sign Up
                    </Button>
                </>
            )}
        </Layout>
    )
}

export default SignIn
