import React from 'react'
import { LinearProgress } from '@mui/material'

const ProgressBar = ({ status }) => {
    const values = {
        'not started': 1,
        started: 33,
        'partially completed': 66,
        completed: 100,
        cancelled: 0,
    }

    return <LinearProgress variant="determinate" value={values[status]} />
}

export default ProgressBar
