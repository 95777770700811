import React, { useEffect, useState } from 'react'
import { DashboardPage } from 'lib/components/layout/page'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { generateBreadcrumbs } from 'lib/utilities'
import { formFields, getService, validationSchema } from '../constants'
import { FormContainer } from 'lib/components/layout/form'
import { FormBuilder } from '@instantia/mui-kit.ui'
import api from 'app/api'
import { toast } from 'react-toastify'

const Update = () => {
    const [service, setService] = useState(null)
    const navigate = useNavigate()
    const { id } = useParams()
    const { pathname } = useLocation()
    const breadcrumbs = generateBreadcrumbs({
        icon: 'HomeRepairService',
        name: 'Services',
        pathname,
        action: 'update',
    })

    const handleUpdateService = async (data) => {
        const res = await api({ entity: 'services', action: 'update', data }).catch((err) => {
            toast.error('Error updating service.')
            console.error(err)
        })
        if (res.status === 200) {
            navigate(-1)
            setService(res.data)
            toast.success('Service updated successfully')
        }
    }

    useEffect(() => {
        getService(id).then((data) => setService(data))
    }, [id])

    if (!service) return ''

    return (
        <DashboardPage title="Update Service" breadcrumbs={breadcrumbs}>
            <FormContainer>
                <FormBuilder
                    title="Update service"
                    initialValues={service}
                    validationSchema={validationSchema}
                    submitButtonText="Update Service"
                    formFields={formFields}
                    handleSubmit={handleUpdateService}
                />
            </FormContainer>
        </DashboardPage>
    )
}

export default Update
