import React from 'react'
import { FormContainer } from 'lib/components/layout/form'
import { FormBuilder } from '@instantia/mui-kit.ui'
import { DashboardPage } from 'lib/components/layout/page'
import { formFields, validationSchema } from '../constants'
import api from 'app/api'
import { toast } from 'react-toastify'
import { generateBreadcrumbs } from 'lib/utilities'
import { useLocation, useNavigate } from 'react-router-dom'

const Create = () => {
    const supplier = {
        name: '',
        description: '',
        unitPrice: 0,
        unit: 'item',
    }
    const navigate = useNavigate()

    const { pathname } = useLocation()
    const breadcrumbs = generateBreadcrumbs({
        icon: 'BusinessCenter',
        name: 'Suppliers',
        pathname,
        action: 'create',
    })

    const handleCreateSupplier = async (data) => {
        const res = await api({ entity: 'suppliers', action: 'create', data }).catch((err) => {
            console.error(err)
            toast.error('Error creating supplier')
        })

        if (res.status === 200) {
            toast.success('Supplier created successfully')
            navigate(-1)
        }
    }

    return (
        <DashboardPage title="Supplier" breadcrumbs={breadcrumbs}>
            <FormContainer>
                <FormBuilder
                    title="New Supplier"
                    initialValues={supplier}
                    validationSchema={validationSchema}
                    submitButtonText="Create Supplier"
                    formFields={formFields}
                    handleSubmit={handleCreateSupplier}
                />
            </FormContainer>
        </DashboardPage>
    )
}

export default Create
