import axios from 'axios'

const prefix = `api/query-store`

const customers = {
    list: (params) => axios.get(`${prefix}/`, { params }),
    create: async (data) => axios.post(`${prefix}/`, data),
    read: async (id) => axios.get(`${prefix}/${id}`),
    query: async (id) => axios.get(`${prefix}/${id}/run`),
    update: async (data) => axios.patch(`${prefix}/${data.id}`, data),
    delete: async (id) => axios.delete(`${prefix}/${id}`),
}

export default customers
