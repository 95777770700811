import axios from 'axios'

const prefix = `api/vehicles`

const vehicles = {
    list: async (params) => axios.get(`${prefix}/`, { params }),
    create: async (vehicle) => axios.post(`${prefix}`, vehicle),
    read: async (vehicle) => axios.get(`${prefix}/${vehicle}`),
    query: async (data) => axios.post(`${prefix}/query`, data),
    update: async (vehicle) => axios.patch(`${prefix}/${vehicle.id}`, vehicle),
    delete: async (vehicle) => axios.delete(`${prefix}/${vehicle}`),
}

export default vehicles
