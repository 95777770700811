import axios from 'axios'

const prefix = `api/services`

const services = {
    list: (params) => axios.get(`${prefix}/`, { params }),
    create: async (service) => axios.post(`${prefix}/`, service),
    read: async (service) => axios.get(`${prefix}/${service}`),
    query: async (data) => axios.post(`${prefix}/query`, data),
    update: async (service) => axios.patch(`${prefix}/${service.id}`, service),
    delete: async (service) => axios.delete(`${prefix}/${service}`),
}

export default services
