import React from 'react'
import logo from 'assets/images/logo/maneja.png'
import { setAuthUser } from 'app/redux/authentication/slice'
import { BasicPage } from '@instantia/mui-kit.ui'
import { useDispatch } from 'react-redux'

const Container = ({ children, loading }) => {
    const dispatch = useDispatch()
    const appBarProps = {
        position: 'static',
        sx: {
            borderRadius: 2,
            margin: '12px',
            width: `calc(100% - ${24}px) !important`,
            bgcolor: '#fff',
            color: '#aaa',
        },
    }

    return (
        <BasicPage
            appTopBar={{
                appBarProps,
                title: { img: { src: logo }, width: 200 },
                settings: [
                    {
                        label: 'logout',
                        onClick: () => {
                            localStorage.removeItem('authToken')
                            dispatch(setAuthUser())
                        },
                    },
                ],
            }}
        >
            {loading ? 'Please wait' : children}
        </BasicPage>
    )
}

export default Container
